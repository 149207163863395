const createClubInfo = data => {
  let clubInfo = [];
  if (data.address && data.postalCode && data.place) {
    clubInfo.push({
      name: 'Adresa',
      label: data.address + ', ' + data.postalCode + ' ' + data.place,
      icon: 'location'
    });
  }
  if (data.pid) {
    clubInfo.push({
      name: 'OIB',
      label: data.pid,
      icon: 'document'
    });
  }
  if (data.president) {
    clubInfo.push({
      name: 'Predsjednik',
      label: data.president,
      icon: 'avatar'
    });
  }
  if (data.secretary) {
    clubInfo.push({
      name: 'Tajnik',
      label: data.secretary,
      icon: 'avatar'
    });
  }
  if (data.contact) {
    clubInfo.push({
      name: 'Kontakt',
      label: data.contact,
      icon: 'document'
    });
  }
  if (data.email) {
    clubInfo.push({
      name: 'Email',
      label: data.email,
      icon: 'document'
    });
  }
  if (data.fb) {
    clubInfo.push({
      name: 'Facebook',
      label: data.fb,
      icon: 'document'
    });
  }
  if (data.web) {
    clubInfo.push({
      name: 'Web',
      label: data.web,
      url: data.web,
      icon: 'document'
    });
  }
  return clubInfo;
};

export default createClubInfo;
