import React, { useState } from 'react';
import { createClub } from '../../actions';
import { useStateValue } from '../../contexts/AppContext';
import ClubForm from '../../components/Forms/ClubForm';
import Page from '../../components/Page';

const ClubNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async formProps => {
    setSubmitting(true);
    await createClub(formProps, dispatch);
    setSubmitting(false);
  };
  return (
    <Page title='Novi klub'>
      <ClubForm onSubmit={onSubmit} submitting={submitting} />
    </Page>
  );
};

export default ClubNew;
