export default (years) => {
  switch (years) {
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return "PROMO";
    case 8:
    case 9:
    case 10:
    case 11:
      return "TRIATLON ŠKOLA";
    case 12:
    case 13:
    case 14:
    case 15:
      return "KADETI I KADETKINJE";
    case 16:
    case 17:
    case 18:
    case 19:
      return "JUNIORI I JUNIORKE";
    default:
      return "SENIORI I SENIORKE APSOLUTNO";
  }
};