import React, { useState, useEffect } from "react";
import { useStateValue } from "../contexts/AppContext";
import Page from "../components/Page";
import Textfield from "@atlaskit/textfield";
import { Button } from "@atlaskit/button/components/Button";
import { useCookies } from "react-cookie";
import { Grid, GridColumn } from "@atlaskit/page";
import Wrapper from "../components/Wrapper";
import Person from "../components/SearchResult";
import { Link } from "react-router-dom";
import RaceEvents from "../components/Tables/RaceEvents";
import { getApiData } from "../actions";
import SearchResults from "../components/SearchResults";
import styled from "styled-components";
import useWindowSize from "../hooks/useWindowSize";

const LinkWrapper = styled.div`
  padding: 16px 0;
`;

const Dashboard = (props) => {
  const [state, dispatch] = useStateValue();
  const [searchFilter, setSearchFilter] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(null);
  const [cookies] = useCookies(["token", "clubs"]);

  useEffect(() => {
    getApiData(
      "maleCup",
      "/api/cupOrder?first=1&categoryId=1&cupId=4&year=2024",
      dispatch
    );
    getApiData(
      "femaleCup",
      "/api/cupOrder?first=1&categoryId=2&cupId=4&year=2024",
      dispatch
    );
  }, []);

  const cupDataToJSX = (tableName) => {
    return (
      state.apiData[tableName] &&
      state.apiData[tableName].map((competitor) => (
        <Person
          key={competitor.id}
          first={{
            id: competitor.id,
            name: competitor.name,
            surname: competitor.surname,
            to: "/competitors/" + competitor.id,
          }}
          second={{
            name: competitor.clubName,
            id: competitor.clubId,
            to: "/clubs/" + competitor.clubId,
          }}
        />
      ))
    );
  };

  const [width, height] = useWindowSize();
  if (width === 0) {
    return <div />;
  }
  return (
    <Page
      title="Hrvatski triatlon savez"
      search={
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <div style={{ maxWidth: 300, marginRight: 10 }}>
            <Textfield
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setShowSearchResults(searchFilter);
                }
              }}
              onChange={(event) => {
                setSearchFilter(event && event.target && event.target.value);
              }}
              placeholder="Tražilica"
            />
          </div>
          <Button
            onClick={() => setShowSearchResults(searchFilter)}
            appearance="primary"
          >
            Pretraži
          </Button>
        </div>
      }
      actions={[
        {
          label: "Dodaj utrku",
          href: "/races/new",
          admin: true,
        },
        {
          label: "Novi natjecatelj",
          href: "/competitors/new",
          clubAdmin: true,
        },
        {
          label: "Moj Klub",
          href: "/clubs/" + (cookies && cookies.clubs && cookies.clubs[0]),
          clubAdmin: "force",
        },
        {
          label: "Prijava na utrku",
          href: "/races",
          clubAdmin: "force",
        },
      ]}
    >
      {showSearchResults ? (
        <SearchResults filter={showSearchResults} />
      ) : width < 850 ? (
        <div>
          <Wrapper
            title="TRIATLON KUP HRVATSKE"
            subheading="Kup Hrvatske čine sva pojedinačna Prvenstva Hrvatske i odobrene utrke za Kup."
          >
            <p>Trenutni poredak kupa</p>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>{cupDataToJSX("maleCup")}</div>
              <div style={{ flex: 1 }}>{cupDataToJSX("femaleCup")}</div>
            </div>
            <LinkWrapper>
              <Link to="/cup">Prikaži sve</Link>
            </LinkWrapper>
          </Wrapper>
          <Wrapper title="SLJEDEĆE UTRKE">
            <RaceEvents first={5} />
            <LinkWrapper>
              <Link to="/races">Prikaži više</Link>{" "}
            </LinkWrapper>
          </Wrapper>
          <Wrapper title="POSLJEDNJE UTRKE">
            <RaceEvents last={5} />
            <LinkWrapper>
              <Link to="/races">Prikaži više</Link>{" "}
            </LinkWrapper>
          </Wrapper>
        </div>
      ) : (
        <Grid style={{width: '100%'}}>
          <GridColumn medium={4}>
            <Wrapper
              title="TRIATLON KUP HRVATSKE"
              subheading="Kup Hrvatske čine sva pojedinačna Prvenstva Hrvatske i odobrene utrke za Kup."
            >
              <p>Trenutni poredak kupa</p>
              <Grid>
                <GridColumn medium={2}>{cupDataToJSX("maleCup")}</GridColumn>
                <GridColumn medium={2}>{cupDataToJSX("femaleCup")}</GridColumn>
              </Grid>
              <LinkWrapper>
                <Link to="/cup">Prikaži sve</Link>
              </LinkWrapper>
            </Wrapper>
          </GridColumn>
          <GridColumn medium={8}>
            <Wrapper title="SLJEDEĆE UTRKE">
              <RaceEvents first={5} />
              <LinkWrapper>
                <Link to="/races">Prikaži više</Link>{" "}
              </LinkWrapper>
            </Wrapper>
            <Wrapper title="POSLJEDNJE UTRKE">
              <RaceEvents last={5} />
              <LinkWrapper>
                <Link to="/races">Prikaži više</Link>{" "}
              </LinkWrapper>
            </Wrapper>
          </GridColumn>
        </Grid>
      )}
    </Page>
  );
};

export default Dashboard;
