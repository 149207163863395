import React, { useEffect } from "react";
import PageHeader from "@atlaskit/page-header";
import Page from "@atlaskit/page";
import styled from "styled-components";
import AdminToolButton from "./AdminToolButton";
import ToolButton from "./ToolButton";
import HeadCoachToolButton from "./HeadCoachToolButton";
import InfoLabel from "./InfoLabel";
import LinkButton from "../LinkButton";
import { Button } from "@atlaskit/button/components/Button";
import { useCookies } from "react-cookie";
import Breadcrumbs from "./Breadcrumbs";
import Flag from "./Flag";
import ClubToolButton from "./ClubToolButton";

const ToolBar = styled.div`
  position: absolute;
  top: 24px;
  right: 16px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-basis: 4;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 20px 50px 10px 50px;
  margin-bottom: 20px;
  text-align: center;
  border-bottom: #bbb5b5 1px solid;
  justify-content: space-evenly;
`;

const MainActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    display: flex;
  }
`;

const BasePage = ({
  title = "",
  info = [],
  img = null,
  otherInfo = null,
  search = null,
  toolBarActions = [],
  actions = [],
  children,
  toolBarSkipAdmin = false,
}) => {
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Page>
      <PageHeader breadcrumbs={<Breadcrumbs title={title} />}>
        {title}
      </PageHeader>
      <Flag />
      <ToolBar>
        {toolBarActions.map((toolBarAction, index) => {
          return <ToolButton key={1} {...toolBarAction} key={index} />;
        })}
        {(cookies.token === "1" || toolBarSkipAdmin) &&
          toolBarActions.map((toolBarAction, index) => {
            return <AdminToolButton key={2} type={toolBarAction} key={index} />;
          })}
        {(cookies.token === "2" || cookies.token === "1" ) &&
          toolBarActions.map((toolBarAction, index) => {
            return <ClubToolButton key={2} type={toolBarAction} key={index} />;
          })}
        {cookies.token === "3" &&
          toolBarActions.map((toolBarAction, index) => {
            return <HeadCoachToolButton type={toolBarAction} key={index} />;
          })}
      </ToolBar>
      {search && search}
      <InfoSection>
        {img}
        {otherInfo}
        <div style={{ flex: 1, flexDirection: "row" }}>
          {info.map((item, index) => {
            if (item.url) {
              return (
                <InfoLabel icon={item.icon} key={index}>
                  <a href={item.url}>{item.url}</a>
                </InfoLabel>
              );
            }
            return (
              <InfoLabel icon={item.icon} key={index}>
                <b>{item.name}:</b> {item.label}
              </InfoLabel>
            );
          })}
        </div>
      </InfoSection>
      <MainActionsWrapper>
        {actions.map((action, index) => {
          if (action.admin && !action.skipAdmin) {
            if (cookies.token !== "1") {
              return null;
            }
          }
          if (action.clubAdmin && !action.skipAdmin) {
            if (action.clubAdmin === "force" && cookies.token !== "2") {
              return null;
            } else if (cookies.token !== "1" && cookies.token !== "2") {
              return null;
            }
          }
          if (action.href) {
            return (
              <LinkButton
                key={index}
                appearance={action.appearance}
                to={action.href}
              >
                {action.label}
              </LinkButton>
            );
          } else if (action.onClick) {
            return (
              <Button
                key={index}
                appearance={action.appearance || "primary"}
                style={{
                  minWidth: "100px",
                  margin: "4px",
                  width: "fit-content",
                  justifyContent: "center",
                }}
                onClick={action.onClick}
              >
                {action.label}
              </Button>
            );
          }
          return null;
        })}
      </MainActionsWrapper>
      {children}
    </Page>
  );
};

export default BasePage;
