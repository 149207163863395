import React from 'react';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter } from '@atlaskit/form';
const fields = [
  {
    name: 'name',
    label: 'Ime kluba',
    isRequired: true
  },
  {
    name: 'address',
    label: 'Adresa',
    isRequired: true
  },
  {
    name: 'postalCode',
    label: 'Poštanski broj',
    isRequired: true
  },
  {
    name: 'place',
    label: 'Mjesto',
    isRequired: true
  },
  {
    name: 'pid',
    label: 'OIB',
    isRequired: true
  },
  {
    name: 'county',
    label: 'Broj Županije https://uprava.gov.hr/UserDocsImages/Lokalna%20samouprava/zupanije_RH.pdf',
    isRequired: true
  },
  {
    name: 'president',
    label: 'Predsjednik',
    isRequired: true
  },
  {
    name: 'secretary',
    label: 'Tajnik',
    isRequired: true
  },
  {
    name: 'contact',
    label: 'Kontakt broj',
    isRequired: true
  },
  {
    name: 'email',
    label: 'Email adresa',
    isRequired: true
  },
  {
    name: 'fb',
    label: 'Facebook stranica'
  },
  {
    name: 'web',
    label: 'Web stranica'
  }
];
const ClubForm = ({ onSubmit, submitting, initialFormProps = {}, submitButtonText = 'Dodaj' }) => {
  return (
    <Form onSubmit={async formProps => await onSubmit(formProps)}>
      {({ formProps = initialFormProps }) => (
        <form {...formProps}>
          {fields.map((field, index) => (
            <Field
              key={index}
              name={field.name}
              defaultValue={initialFormProps[field.name]}
              isRequired={field.isRequired}
              label={field.label}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField autoComplete='off' {...fieldProps} />
                </>
              )}
            </Field>
          ))}
          <FormFooter>
            <ButtonGroup>
              <Button type='submit' appearance='primary' isLoading={submitting}>
                {submitButtonText}
              </Button>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default ClubForm;
