import React from 'react';
import styled from 'styled-components';

const BaseWrapper = styled.div`
  background-color: rgb(244, 245, 247);
  color: rgb(52, 69, 99);
  margin: 24px 0;
  text-align: center;
`;

const InsideWrapper = styled.div`
  
`;

const Heading = styled.h4`
  color: white;
  text-align: center;
  margin: 0px;
`;

const Title = styled.div`
  background-color: #0747a6;
  padding: 16px 8px;
`;

const Subheading = styled.p`
  font-family: 'eurostile-condensed', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !important;
  padding: 0 8px;
  text-align: center;
`;

const Wrapper = props => {
  return (
    <BaseWrapper {...props}>
      {props && props.title && (
        <Title>
          <Heading>{props.title}</Heading>
        </Title>
      )}
      {props && props.subheading && (
        <>
          <Subheading>{props.subheading}</Subheading>
          <hr />
        </>
      )}
      <InsideWrapper>{props.children}</InsideWrapper>
    </BaseWrapper>
  );
};

export default Wrapper;
