import React from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@atlaskit/icon/glyph/settings";

const ToolButton = ({ type }) => {
  if (type === "settings") {
    return (
      <Link to={window.location.pathname.replace("/edit", "") + "/settings"} style={{ marginLeft: '20px' }}>
        <SettingsIcon size="large" primaryColor="black" />
      </Link>
    );
  }
  return null;
};

export default ToolButton;
