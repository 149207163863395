import moment from "moment";

const createRaceInfo = (data) => {
  let raceInfo = [];
  const isGroupRace = data.raceTypeId === 14;
  if (data.location) {
    raceInfo.push({ name: "Lokacija", label: data.location, icon: "location" });
  }
  if (data.date) {
    raceInfo.push({
      name: "Datum",
      label: moment(data.date).format("DD.MM.YYYY."),
      icon: "time",
    });
  }
  if (data.categoryName && data.sex) {
    raceInfo.push({
      name: "Kategorija",
      label: data.categoryName + " " + (isGroupRace ? "" : data.sex),
      icon: "avatar",
    });
  }
  if (data.clubName) {
    raceInfo.push({
      name: "Klub",
      label: data.clubName,
      icon: "avatar",
    });
  }
  if (data.raceTypeName) {
    raceInfo.push({
      name: "Tip utrke",
      label: data.raceTypeName,
      icon: "race",
    });
  }
  if (data.cupName) {
    raceInfo.push({
      name: "Kup",
      label: data.cupName,
      icon: "race",
    });
  }
  return raceInfo;
};

export default createRaceInfo;
