import React, { useState, useEffect, useRef } from "react";
import TextField from "@atlaskit/textfield";
import Page from "../../components/Page";
import { useStateValue } from "../../contexts/AppContext";
import { getApiData } from "../../actions";
import ReactTable from "react-table";
import moment from "moment";
import filterData from "../../helpers/filterData";
import { Link } from "react-router-dom";
import delayedCallback from "../../helpers/delayedCallback";
import Wrapper from "../../components/Wrapper";
import licenseHelper from "../../helpers/licenseHelper";
import Select from "@atlaskit/select";

const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2018 },
  (_, index) => {
    return { value: 2019 + index, label: 2019 + index };
  }
);

const Competitors = () => {
  const competitors = useRef(null);
  const tableName = `clubCompetitors-${year}`;
  const [state, dispatch] = useStateValue();
  const [filter, setFilter] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    getApiData(tableName, `/api/competitor?year=${year}`, dispatch);
  }, [year]);

  const searchInputChange = (event) => {
    event.persist();
    if (event.target.value) {
      delayedCallback(event, setFilter);
    } else {
      setFilter(null);
    }
  };

  return (
    <Page
      title="Licencirani natjecatelji"
      actions={[
        {
          label: "Dodaj novog natjecatelja",
          href: "/competitors/new",
          admin: true,
        },
      ]}
      search={
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ maxWidth: 500, width: "100%" }}>
            <Select
              options={yearOptions}
              value={yearOptions.find((o) => o.value == year)}
              onChange={(option) => {
                setYear(option.value);
              }}
              placeholder="Odaberite godinu"
            />
            <div style={{ marginTop: 20 }} />
            <TextField
              onChange={searchInputChange}
              placeholder="Filtriraj natjecatelje"
            />
          </div>
        </div>
      }
      toolBarActions={[
        { type: "data", url: `/api/natjecatelji.xls?year=${year}` },
      ]}
    >
      {state.apiData[tableName] && (
        <Wrapper>
          <ReactTable
            key={year}
            showPagination={state.apiData[tableName].length > 20}
            data={filterData(state.apiData[tableName], filter)}
            columns={[
              {
                Header: "Id",
                accessor: "id",
                maxWidth: 100,
              },
              {
                Header: "Licenca",
                accessor: "Licenses",
                maxWidth: 100,
                Cell: (row) => <span>{licenseHelper(row.value)}</span>,
              },
              {
                Header: "Ime",
                accessor: "name",
                maxWidth: 300,
                Cell: (row) => (
                  <Link to={`/competitors/${row.original.id}`}>
                    {row.value}
                  </Link>
                ),
              },
              {
                Header: "Prezime",
                accessor: "surname",
                maxWidth: 300,
                Cell: (row) => (
                  <Link to={`/competitors/${row.original.id}`}>
                    {row.value}
                  </Link>
                ),
              },
              {
                Header: "Klub",
                accessor: "clubData",
                maxWidth: 300,
                Cell: (row) =>
                  row.value ? (
                    <Link to={`/clubs/${row.value.id}`}>{row.value.name}</Link>
                  ) : null,
              },
              {
                Header: "Kategorije",
                accessor: "categories",
                maxWidth: 300,
                Cell: (row) => <span>{row.value && row.value.join(" ")}</span>,
              },
              {
                Header: "Datum rođenja",
                accessor: "dateOfBirth",
                maxWidth: 200,
                Cell: (row) => (
                  <span>
                    {row.value ? moment(row.value).format("DD.MM.YYYY.") : "-"}
                  </span>
                ),
              },
              {
                Header: "Spol",
                accessor: "sex",
                maxWidth: 100,
              },
            ]}
            defaultSorted={[
              {
                id: "id",
                desc: true,
              },
            ]}
            defaultPageSize={20}
            className="-striped -highlight"
          />
        </Wrapper>
      )}
    </Page>
  );
};

export default Competitors;
