import React from "react";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import LocationIcon from "@atlaskit/icon/glyph/location";
import EmojiFrequentIcon from "@atlaskit/icon/glyph/emoji/frequent";
import PersonIcon from "@atlaskit/icon/glyph/person";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import BitbucketBuildsIcon from "@atlaskit/icon/glyph/bitbucket/builds";
import WorldIcon from "@atlaskit/icon/glyph/world";
import DocumentIcon from "@atlaskit/icon/glyph/document";
import CalendarFilledIcon from "@atlaskit/icon/glyph/calendar-filled";
import styled from "styled-components";

const BaseToolButton = styled.div`
  min-width: 150px;
  margin-right: 20px;
`;

const getIcon = (icon) => {
  switch (icon) {
    case "settings":
      return SettingsIcon;
    case "location":
      return LocationIcon;
    case "time":
      return EmojiFrequentIcon;
    case "avatar":
      return PersonIcon;
    case "people":
      return PeopleGroupIcon;
    case "race":
      return BitbucketBuildsIcon;
    case "document":
      return DocumentIcon;
    case "date":
      return CalendarFilledIcon;
    default:
      return WorldIcon;
  }
};

const ToolButton = ({ icon, children }) => {
  const Icon = getIcon(icon);

  return (
    <BaseToolButton>
      <Icon size="small" primaryColor="black" />
      <span>{children}</span>
    </BaseToolButton>
  );
};

export default ToolButton;
