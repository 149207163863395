import React from 'react';
import Button from '@atlaskit/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BaseLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  margin: 4px;
  align-items: center;
`;

const BaseButton = styled(Button)`
  text-align: center;
  justify-content: center;
`;

const LinkButton = props => {
  const { to, disabled, ...otherProps } = props;
  return (
    <BaseLink to={to || '#'}>
      <BaseButton
        appearance={props.appearance || 'primary'}
        isDisabled={disabled}
      >
        {props.children}{' '}
      </BaseButton>
    </BaseLink>
  );
};

export default LinkButton;
