const formatClubOptions = clubOptions => {
  if (!clubOptions) return [];
  try {
    clubOptions = clubOptions.map(value => {
      return {
        label: value.name,
        value: value.id
      };
    });
  } catch (err) {
    clubOptions = [
      {
        label: clubOptions.name,
        value: clubOptions.id
      }
    ];
  }
  return clubOptions;
};

export default formatClubOptions;
