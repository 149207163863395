import React, { Suspense } from "react";
import PrivateRoute from "./components/PrivateRoute";
import { Route, Switch } from "react-router-dom";

import { Ranks, RankNew, RankSettings } from "./layouts/ranks/index.js";
import Login from "./layouts/Login.jsx";
import Dashboard from "./layouts/Dashboard.jsx";
import { Clubs, Club } from "./layouts/clubs";
import { Moderators, ModeratorNew } from "./layouts/moderators";
import { Competitors, Competitor } from "./layouts/competitors";
import { Races, Race, RaceRegister, RaceStartList } from "./layouts/races";
import { Cup } from "./layouts/cup";
import { Measures } from "./layouts/measures";

function Routes() {
  return (
    <Switch>
      <Suspense fallback={<div>Loading...</div>}>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />

        <Route exact path="/clubs" component={Clubs} />
        <Route exact path="/clubs/:id" component={Club} />
        <Route
          exact
          path="/clubs/:id/settings"
          component={React.lazy(() => import("./layouts/clubs/ClubSettings"))}
        />
        <Route
          exact
          path="/clubs/new"
          component={React.lazy(() => import("./layouts/clubs/ClubNew"))}
        />

        <Route exact path="/moderators" component={Moderators} />
        <Route exact path="/moderators/new" component={ModeratorNew} />
        <Route
          exact
          path="/headcoach/new"
          component={React.lazy(() => import("./layouts/moderators/HeadCoach"))}
        />

        <Route exact path="/competitors" component={Competitors} />
        <Route
          exact
          path="/competitors/new"
          component={React.lazy(() =>
            import("./layouts/competitors/CompetitorNew")
          )}
        />
        <Route exact path="/competitors/:id" component={Competitor} />
        <Route
          exact
          path="/competitors/:id/settings"
          component={React.lazy(() =>
            import("./layouts/competitors/CompetitorSettings")
          )}
        />
        <Route
          exact
          path="/competitors/:id/headcoachsettings"
          component={React.lazy(() =>
            import("./layouts/competitors/HeadCoachCompetitorSettings")
          )}
        />

        <Route
          exact
          path="/licenses"
          component={React.lazy(() => import("./layouts/Licenses"))}
        />

        <Route exact path="/races" component={Races} />
        <Route exact path="/race-event/:id" component={Races} />
        <PrivateRoute
          exact
          path="/races/new"
          component={React.lazy(() => import("./layouts/races/RaceNew"))}
        />
        <Route exact path="/races/:id" component={Race} />
        <PrivateRoute
          exact
          path="/races/:id/edit"
          component={React.lazy(() => import("./layouts/races/RaceEdit"))}
        />
        <Route exact path="/races/:id/startlist" component={RaceStartList} />
        <PrivateRoute
          exact
          path="/races/:id/settings"
          component={React.lazy(() => import("./layouts/races/RaceSettings"))}
        />
        <PrivateRoute
          exact
          path="/races/:id/register"
          component={RaceRegister}
        />

        <Route
          exact
          path="/raceTypes"
          component={React.lazy(() => import("./layouts/raceTypes/RaceTypes"))}
        />
        <Route
          exact
          path="/raceTypes/new"
          component={React.lazy(() =>
            import("./layouts/raceTypes/RaceTypeNew")
          )}
        />
        <Route
          exact
          path="/raceTypes/:id/settings"
          component={React.lazy(() =>
            import("./layouts/raceTypes/RaceTypeSettings")
          )}
        />

        <Route exact path="/ranks" component={Ranks} />
        <Route exact path="/ranks/new" component={RankNew} />
        <Route exact path="/ranks/:id/settings" component={RankSettings} />

        <Route exact path="/measures" component={Measures} />

        <Route
          exact
          path="/categories"
          component={React.lazy(() =>
            import("./layouts/categories/Categories")
          )}
        />
        <Route
          exact
          path="/categories/new"
          component={React.lazy(() =>
            import("./layouts/categories/CategoryNew")
          )}
        />
        <Route
          exact
          path="/categories/:id/settings"
          component={React.lazy(() =>
            import("./layouts/categories/CategorySettings")
          )}
        />

        <Route exact path="/cup" component={Cup} />

        <Route
          exact
          path="/data"
          component={React.lazy(() => import("./layouts/data/Data"))}
        />
        <Route
          exact
          path="/dataGeneral"
          component={React.lazy(() => import("./layouts/data/DataGeneral"))}
        />

        <Route
          exact
          path="/settings"
          component={React.lazy(() => import("./layouts/settings/Settings"))}
        />

        <Route
          exact
          path="/all-competitors"
          component={React.lazy(() =>
            import("./layouts/competitors/AllCompetitors")
          )}
        />

        <Route exact path="/public" component={Cup} />

        <Route
          exact
          path="/clubPoints"
          component={React.lazy(() => import("./layouts/data/ClubPoints"))}
        />
      </Suspense>
    </Switch>
  );
}

export default Routes;
