import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import licenseHelper from '../../../helpers/licenseHelper';

const createCompetitorInfo = data => {
  let competitorInfo = [];
  if (data.id) {
    competitorInfo.push({
      name: 'ID',
      label: data.id,
      icon: 'document'
    });
  }
  competitorInfo.push({
    name: 'Licenca',
    label: licenseHelper(data.Licenses),
    icon: 'document'
  });
  if (data.categories) {
    competitorInfo.push({
      name: 'Kategorije',
      label: data.categories.join(' '),
      icon: 'document'
    });
  }
  if (data.pid) {
    competitorInfo.push({
      name: 'OIB',
      label: data.pid,
      icon: 'document'
    });
  }
  if (data.dateOfBirth) {
    competitorInfo.push({
      name: 'Datum rođenja',
      label: moment(data.dateOfBirth).format('DD.MM.YYYY.'),
      icon: 'date'
    });
  }
  if (data.sex) {
    competitorInfo.push({
      name: 'Spol',
      label: data.sex === 'M' ? 'Muškarac' : 'Žena',
      icon: 'avatar'
    });
  }
  if (data.Club && data.Club.name) {
    competitorInfo.push({
      name: 'Klub',
      label: <Link to={`/clubs/${data.Club.id}`}>{data.Club.name}</Link>,
      icon: 'avatar'
    });
  }

  return competitorInfo;
};

export default createCompetitorInfo;
