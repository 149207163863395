import React, { Fragment, useState } from "react";
import GlobalNavigation from "@atlaskit/global-navigation";
import {
  GroupHeading,
  HeaderSection,
  Item,
  LayoutManager,
  MenuSection,
  NavigationProvider,
  Separator,
} from "@atlaskit/navigation-next";
import { logOut } from "../actions";
import { useCookies } from "react-cookie";
import Logo from "./Logo";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import {
  AtlassianNavigation,
  PrimaryButton,
  PrimaryDropdownButton,
} from "@atlaskit/atlassian-navigation";
import Popup from "@atlaskit/popup";
import { ButtonItem, PopupMenuGroup, Section } from "@atlaskit/menu";
import useWindowSize from "../hooks/useWindowSize";

const DashboardNavigation = withRouter((props) => (
  <GlobalNavigation
    productIcon={() => <Logo size="small" />}
    onProductClick={() => {
      props.history.push("/");
    }}
  />
));

const Header = styled.h1`
  color: white;
  text-align: center;
`;

const ItemContent = styled.span`
  color: #fff;
  text-decoration: none;
`;

const PopupContents = () => (
  <PopupMenuGroup>
    <Section>
      <ButtonItem description="But what is an Atlassian, anyway?">
        About
      </ButtonItem>
    </Section>
  </PopupMenuGroup>
);

const ExploreDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popup
      content={PopupContents}
      isOpen={isOpen}
      placement="bottom-start"
      onClose={() => setIsOpen(false)}
      trigger={(triggerProps) => (
        <PrimaryDropdownButton
          {...triggerProps}
          isSelected={isOpen}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          Ostalo
        </PrimaryDropdownButton>
      )}
    />
  );
};

const MobileNavigation = withRouter(
  ({ token, clubId, onClickLogout, history }) => {
    return (
      <AtlassianNavigation
        label="site"
        renderProductHome={() => (
          <Header style={{ marginRight: 20 }}>
            <Logo size="small" />
          </Header>
        )}
        primaryItems={[
          <PrimaryButton
            onClick={() => {
              history.push("/");
            }}
          >
            Početna
          </PrimaryButton>,
          <PrimaryButton
            onClick={() => {
              history.push("/races");
            }}
          >
            Utrke
          </PrimaryButton>,
          <PrimaryButton
            onClick={() => {
              history.push("/cup");
            }}
          >
            Kup
          </PrimaryButton>,
          <PrimaryButton
            onClick={() => {
              history.push("/clubs");
            }}
          >
            Klubovi
          </PrimaryButton>,
          <PrimaryButton
            onClick={() => {
              history.push("/competitors");
            }}
          >
            Natjecatelji
          </PrimaryButton>,
          ...(clubId
            ? [
                <PrimaryButton
                  onClick={() => {
                    history.push("/clubs/" + clubId);
                  }}
                >
                  Natjecatelji
                </PrimaryButton>,
              ]
            : []),
          ,
          <PrimaryButton
            onClick={() => {
              history.push("/measures");
            }}
          >
            Testiranje sportaša
          </PrimaryButton>,
          <PrimaryButton
            onClick={() => {
              history.push("/clubPoints");
            }}
          >
            Vrednovanje klubova
          </PrimaryButton>,
          token ? (
            <PrimaryButton onClick={() => onClickLogout()}>
              Odjava
            </PrimaryButton>
          ) : (
            <PrimaryButton
              onClick={() => {
                history.push("/login");
              }}
            >
              Prijava
            </PrimaryButton>
          ),
        ]}
      />
    );
  }
);

const ProductNavigation = withRouter(
  ({ token, clubId, onClickLogout, history }) => {
    return (
      <Fragment>
        <HeaderSection>
          {({ className }) => <Header className={className}>HTS</Header>}
        </HeaderSection>
        <MenuSection>
          {({ className }) => (
            <div className={className}>
              <GroupHeading>Izbornik</GroupHeading>
              {token ? (
                <>
                  <Item
                    onClick={() => {
                      history.push("/dashboard");
                    }}
                    text={<ItemContent>Upravljačka ploča</ItemContent>}
                  />
                  {clubId && (
                    <Item
                      onClick={() => {
                        history.push("/clubs/" + clubId);
                      }}
                      text={<ItemContent>Moj klub</ItemContent>}
                    />
                  )}
                  <Item
                    onClick={() => {
                      history.push("/clubs");
                    }}
                    text={<ItemContent>Klubovi</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/races");
                    }}
                    text={<ItemContent>Utrke</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/cup");
                    }}
                    text={<ItemContent>Kup</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/competitors");
                    }}
                    text={<ItemContent>Licencirani natjecatelji</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/measures");
                    }}
                    text={<ItemContent>Testiranje sportaša</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/clubPoints");
                    }}
                    text={<ItemContent>Vrednovanje klubova</ItemContent>}
                  />
                </>
              ) : (
                <>
                  <Item
                    onClick={() => {
                      history.push("/clubs");
                    }}
                    text={<ItemContent>Klubovi</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/races");
                    }}
                    text={<ItemContent>Utrke</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/cup");
                    }}
                    text={<ItemContent>Kup</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/competitors");
                    }}
                    text={<ItemContent>Natjecatelji</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/measures");
                    }}
                    text={<ItemContent>Testiranje sportaša</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/clubPoints");
                    }}
                    text={<ItemContent>Vrednovanje klubova</ItemContent>}
                  />
                </>
              )}

              {token === "1" && (
                <>
                  <Separator />
                  <GroupHeading>Admin</GroupHeading>
                  <Item
                    onClick={() => {
                      history.push("/all-competitors");
                    }}
                    text={<ItemContent>Svi natjecatelji</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/licenses");
                    }}
                    text={<ItemContent>Licence</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/moderators");
                    }}
                    text={<ItemContent>Moderatori</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/raceTypes");
                    }}
                    text={<ItemContent>Tipovi utrka</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/ranks");
                    }}
                    text={<ItemContent>Tipovi kupova</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/categories");
                    }}
                    text={<ItemContent>Kategorije</ItemContent>}
                  />
                  <Item
                    onClick={() => {
                      history.push("/data");
                    }}
                    text={<ItemContent>Analitika</ItemContent>}
                  />
                </>
              )}
              <Separator />
              <GroupHeading>Ostalo</GroupHeading>
              {token && (
                <Item
                  onClick={() => {
                    history.push("/settings");
                  }}
                  text={<ItemContent>Postavke</ItemContent>}
                />
              )}
              {token ? (
                <Item
                  text="Log out"
                  onClick={async () => await onClickLogout()}
                />
              ) : (
                <Item
                  onClick={() => {
                    history.push("/login");
                  }}
                  text={<ItemContent>Login</ItemContent>}
                />
              )}
            </div>
          )}
        </MenuSection>
      </Fragment>
    );
  }
);

const ChildrenDiv = styled.div`
  margin-left: 30px;
`;

const Navigation = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token", "clubs"]);
  const {
    children,
    location: { pathname },
  } = props;
  if (pathname.indexOf("public") === -1) {
    const [width, height] = useWindowSize();

    return (
      <NavigationProvider>
        {width === 0 ? null : cookies.token != "1" || width < 800 ? (
          <div>
            <MobileNavigation
              token={cookies.token}
              clubId={cookies.clubs && cookies.clubs[0]}
              onClickLogout={async () => {
                if (await logOut()) {
                  removeCookie("token");
                  removeCookie("clubs");
                }
              }}
            />
            <div style={{ padding: 10 }}>{children}</div>
          </div>
        ) : (
          <LayoutManager
            globalNavigation={() => <DashboardNavigation />}
            productNavigation={() => (
              <ProductNavigation
                token={cookies.token}
                clubId={cookies.clubs && cookies.clubs[0]}
                onClickLogout={async () => {
                  if (await logOut()) {
                    removeCookie("token");
                    removeCookie("clubs");
                  }
                }}
              />
            )}
            containerNavigation={null}
          >
            <ChildrenDiv>{children}</ChildrenDiv>
          </LayoutManager>
        )}
      </NavigationProvider>
    );
  }
  return { children };
};

export default withRouter(Navigation);
