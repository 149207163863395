import React from "react";
import TextField from "@atlaskit/textfield";
import Button, { ButtonGroup } from "@atlaskit/button";
import Form, { Field, FormFooter } from "@atlaskit/form";
import Select, { CountrySelect } from "@atlaskit/select";
import { groupedCountries } from "../../assets/data/allCountries";
import formatClubOptions from "../../helpers/formatClubOptions";
import DatePicker from "react-date-picker";
import { useCookies } from "react-cookie";
import Spinner from "@atlaskit/spinner";

const sexOptions = [
  { value: "M", label: "Muškarac" },
  { value: "Ž", label: "Žena" }
];

const fields = [
  {
    name: "name",
    label: "Ime",
    isRequired: true
  },
  {
    name: "surname",
    label: "Prezime",
    isRequired: true
  },
  {
    name: "globalId",
    label: "Triathlon.org ID",
    isRequired: false,
    isAdmin: true,
    type: "number"
  }
];
const CompetitorForm = ({
  onSubmit,
  submitting,
  initialFormProps = {},
  clubOptions = [],
  submitButtonText = "Dodaj"
}) => {
  const [cookies] = useCookies(["token"]);
  if (clubOptions.length) {
    return (
      <Form
        onSubmit={async formProps => {
          let undefinedProps = {};
          Object.keys(formProps)
            .filter(key => !formProps[key])
            .forEach(key => (undefinedProps[key] = initialFormProps[key]));
          formProps = {
            ...formProps,
            ...undefinedProps
          };
          if (!formProps.club) {
            formProps.club =
              clubOptions.length === 1
                ? formatClubOptions(clubOptions)[0]
                : initialFormProps["club"]
                ? formatClubOptions(clubOptions).filter(
                    option => option.value === initialFormProps["club"]
                  )[0]
                : clubOptions.length > 1
                ? formatClubOptions(clubOptions)[0]
                : null;
          }
          await onSubmit({ ...formProps });
        }}
      >
        {({ formProps = initialFormProps }) => (
          <form {...formProps}>
            {fields.map((field, index) =>
              !field.isAdmin ? (
                <Field
                  key={index}
                  name={field.name}
                  defaultValue={initialFormProps[field.name]}
                  isRequired={field.isRequired}
                  label={field.label}
                >
                  {({ fieldProps }) => (
                    <>
                      <TextField
                        autoComplete="off"
                        {...fieldProps}
                        type={field.type || "text"}
                      />
                    </>
                  )}
                </Field>
              ) : null
            )}
            <Field
              name="dateOfBirth"
              isRequired={true}
              label="Datum rođenja"
              defaultValue={new Date(initialFormProps.dateOfBirth)}
            >
              {({ fieldProps }) => (
                <>
                  <br />
                  <DatePicker {...fieldProps} />
                </>
              )}
            </Field>

            <Field label="Nacionalnost" name="nationality">
              {({ fieldProps }) => (
                <>
                  <CountrySelect
                    {...fieldProps}
                    placeholder="Nacionalnost"
                    options={groupedCountries}
                    defaultValue={
                      initialFormProps["nationality"]
                        ? groupedCountries[1].options.filter(
                            option =>
                              option.abbr === initialFormProps["nationality"]
                          )[0]
                        : null
                    }
                  />
                </>
              )}
            </Field>
            <Field
              name="pid"
              defaultValue={initialFormProps["pid"]}
              isRequired
              label="OIB"
            >
              {({ fieldProps }) => (
                <>
                  <TextField autoComplete="off" {...fieldProps} type="text" />
                </>
              )}
            </Field>
            <Field label="Spol" name="sex">
              {({ fieldProps }) => (
                <>
                  <Select
                    {...fieldProps}
                    options={sexOptions}
                    defaultValue={
                      initialFormProps["sex"]
                        ? sexOptions.filter(
                            option => option.value === initialFormProps["sex"]
                          )[0]
                        : null
                    }
                    placeholder="Spol"
                  />
                </>
              )}
            </Field>

            <Field label="Klub" name="club">
              {({ fieldProps }) => (
                <>
                  <Select
                    {...fieldProps}
                    options={formatClubOptions(clubOptions)}
                    placeholder="Odaberite klub"
                    defaultValue={
                      clubOptions.length === 1
                        ? formatClubOptions(clubOptions)[0]
                        : initialFormProps["club"]
                        ? formatClubOptions(clubOptions).filter(
                            option => option.value === initialFormProps["club"]
                          )[0]
                        : clubOptions.length > 1
                        ? formatClubOptions(clubOptions)[0]
                        : null
                    }
                  />
                </>
              )}
            </Field>

            {fields.map((field, index) =>
              field.isAdmin && cookies.token === "1" ? (
                <Field
                  key={index}
                  name={field.name}
                  defaultValue={initialFormProps[field.name]}
                  isRequired={field.isRequired}
                  label={field.label}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <TextField
                        autoComplete="off"
                        {...fieldProps}
                        type={field.type || "text"}
                      />
                    </>
                  )}
                </Field>
              ) : null
            )}
            <FormFooter>
              <ButtonGroup>
                <Button
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  {submitButtonText}
                </Button>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    );
  } else {
    return <Spinner />;
  }
};

export default CompetitorForm;
