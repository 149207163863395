import React, { useState, useEffect } from "react";
import { getData } from "../../actions";
import axios from "axios";
import ResultTable from "../../components/Tables/ResultTable";
import Page from "../../components/Page";
import createRaceInfo from "./helpers/createRaceInfo";
import { useStateValue } from "../../contexts/AppContext";
import { Button } from "@atlaskit/button/components/Button";
import Spinner from "@atlaskit/spinner";

const helperFunctions = async (id, setRaceData, setIsLoading) => {
  const response = await getData("/api/race", id);
  setRaceData(response.data);
  setIsLoading(false);
};

const RaceEdit = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [raceData, setRaceData] = useState({});
  useEffect(() => {
    helperFunctions(id, setRaceData, setIsLoading);
  }, []);
  const [appState, dispatch] = useStateValue();

  const pullResults = async () => {
    const linkedRaceId = raceData.linkedRace.id;
    const formData = new FormData();
    formData.append("linkedRaceId", linkedRaceId);
    formData.append("raceId", id);
    try {
      setIsLoading(true);
      const response = await axios.post("/api/result-pull", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);

      if (response.status === 200) {
        window.location.reload();
      } else {
        dispatch({ type: "error", payload: "Something went wrong" });
      }
    } catch (e) {
      dispatch({ type: "error", payload: "Something went wrong" });
    }
  };

  const onFormSubmit = async (e) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("raceId", id);
    try {
      setIsLoading(true);
      const response = await axios.post("/api/result", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsLoading(false);

      if (response.status === 200) {
        window.location.reload();
      } else {
        dispatch({ type: "error", payload: "Something went wrong" });
      }
    } catch (e) {
      dispatch({ type: "error", payload: "Something went wrong" });
    }
  };

  const deleteResults = async () => {
    setIsLoading(true);
    const response = await axios.delete(`/api/result?raceId=${id}`);
    setIsLoading(false);
    if (response.status === 200) {
      window.location.reload();
    } else {
      dispatch({ type: "error", payload: "Something went wrong" });
    }
  };

  return (
    <Page
      title={raceData.name}
      toolBarActions={["settings"]}
      info={createRaceInfo(raceData)}
    >
      <Button appearance="primary" onClick={deleteResults}>
        Obriši rezultate utrke
      </Button>
      <br />
      <ResultTable
        editable
        tableName={`race-${id}`}
        apiRoute={`/api/result?raceId=${id}`}
        raceData={raceData}
      />

      <br />
      {raceData && [3, 4].includes(raceData.categoryId) && raceData.linkedRace && (
        <div>
          <p>
            Ova utrka je održana u sklopu{" "}
            <a href={"/races/" + raceData.linkedRace.id}>Seniorske utrke</a>
          </p>
          <p>
            Rezultate za Seniorsku utrku{" "}
            <b>{raceData.linkedRace.results.length > 0 ? "IMAMO" : "NEMAMO"}</b>
          </p>
          {raceData.linkedRace.results.length > 0 && (
            <Button appearance="primary" onClick={pullResults}>
              Klikom ovdje povući će se svi Juniori iz Seniorske utrke
            </Button>
          )}
        </div>
      )}
      <form>
        <h1>Upload rezultata</h1>
        <input type="file" onChange={async (e) => await onFormSubmit(e)} />
      </form>
    </Page>
  );
};

export default RaceEdit;
