import React from 'react';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter } from '@atlaskit/form';

const RankForm = ({
  onSubmit,
  submitting,
  initialFormProps = {},
  submitButtonText = 'Dodaj'
}) => {
  return (
    <Form onSubmit={async formProps => await onSubmit(formProps)}>
      {({ formProps = initialFormProps }) => (
        <form {...formProps}>
          <Field
            name='name'
            defaultValue={initialFormProps['name']}
            isRequired={true}
            label='Ime ranga'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='points'
            defaultValue={initialFormProps['points']}
            isRequired={true}
            label='Bodovi za kup'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='number' />
              </>
            )}
          </Field>
          <FormFooter>
            <ButtonGroup>
              <Button type='submit' appearance='primary' isLoading={submitting}>
                {submitButtonText}
              </Button>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default RankForm;
