import React, { useState, useEffect } from 'react';
import { getData, updateData } from '../../actions';
import ClubForm from '../../components/Forms/ClubForm';
import { useStateValue } from '../../contexts/AppContext';
import Page from '../../components/Page';
import { DeleteModal } from '../../components/Modals';
import ClubData from './ClubData';

const helperFunctions = async (id, setClubData) => {
  const response = await getData('/api/club', id);
  setClubData(response.data);
};

const ClubSettings = props => {
  const { id } = props.match.params;
  const [isOpen, setIsOpen] = useState(false);
  const [clubData, setClubData] = useState({});
  const [state, dispatch] = useStateValue();
  useEffect(() => {
    helperFunctions(id, setClubData);
  }, []);
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async formProps => {
    setSubmitting(true);
    await updateData('/api/club', { ...formProps, id }, dispatch, '/clubs');
    setSubmitting(false);
  };

  return (
    <Page
      title={clubData.name || 'Klub'}
    >
      <h3>Postavke</h3>
      <ClubForm
        onSubmit={onSubmit}
        submitting={submitting}
        initialFormProps={clubData}
        submitButtonText='Promijeni'
      />
      <br/>
      <br/>
      <br/>
      <br/>
      <ClubData id={id}/>
      {isOpen && (
        <DeleteModal
          onClose={() => setIsOpen(false)}
          infoText='Upišite ime kluba kojeg ćete obrisati'
          value={clubData.name}
          deleteRoute='/api/club'
          id={id}
          redirect='/clubs'
        />
      )}
    </Page>
  );
};

export default ClubSettings;
