import React from "react";
import { Link } from "react-router-dom";
import SettingsIcon from "@atlaskit/icon/glyph/settings";

const HeadCoachToolButton = ({ type }) => {
  if (type === "headCoachSettings") {
    return (
      <Link
        to={
          window.location.pathname.replace("/edit", "") + "/headcoachsettings"
        }
      >
        <SettingsIcon size="large" primaryColor="black" />
      </Link>
    );
  }
  return null;
};

export default HeadCoachToolButton;
