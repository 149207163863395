export default (state, action) => {
  switch (action.type) {
    case "apiData":
      const newApiData = state.apiData;
      newApiData[action.payload.tableName] = action.payload.data;
      return {
        ...state,
        apiData: newApiData,
      };
    case "userData":
      return {
        ...state,
        user: action.payload,
      };
    case "redirect":
      state.history.push(action.payload);
      return {
        ...state,
        history: state.history,
      };
    case "removeLicense":
      state.apiData["pendingLicences"] = state.apiData[
        "pendingLicences"
      ].filter((data) => data.licenseId != action.payload.id);
      return {
        ...state,
      };
    case "clearError":
      return {
        ...state,
        errors: {},
      };
    case "error":
      return {
        ...state,
        errors: {
          error: {
            description: "",
            title: action.payload,
          },
        },
      };
    case "showRaceEvents":
      let newRaceEvents = [];
      if (!state.showRaceEvents) {
        state.showRaceEvents = [];
      }
      if (state.showRaceEvents.includes(action.payload)) {
        state.showRaceEvents.splice(state.showRaceEvents.indexOf(action.payload), 1);
        newRaceEvents = [...state.showRaceEvents];
      } else {
        newRaceEvents = [...state.showRaceEvents, action.payload];
      }
      return {
        ...state,
        showRaceEvents: newRaceEvents,
      };
    default:
      return state;
  }
};
