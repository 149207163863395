import React, { useEffect } from 'react';
import { useStateValue } from '../contexts/AppContext';
import { getApiData } from '../actions';
import SearchResult from './SearchResult';
import { Grid, GridColumn } from '@atlaskit/page';
import Wrapper from './Wrapper';
import { Link } from 'react-router-dom';
import styled from  'styled-components';

const LinkWrapper = styled.div`
  padding: 16px 0;
  width: 100%;
  text-align:center;
`;

const SearchResults = props => {
  const { filter } = props;
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    getApiData(
      'competitorSearchResults',
      '/api/competitor?name=' + filter,
      dispatch
    );
    getApiData('raceSearchResults', '/api/race?name=' + filter, dispatch);
    getApiData(
      'clubSearchResults',
      '/api/all_clubs?filter=' + filter,
      dispatch
    );
  }, [filter]);

  const searchDataToJsx = (tableName, firstRoute, secondRoute) => {
    return (
      <>
        {state.apiData[tableName] && state.apiData[tableName].length > 0 ? (
          state.apiData[tableName]
            .reduce((prev, curr) => {
              prev.length < 5 && prev.push(curr);
              return prev;
            }, [])
            .map(data => (
              <GridColumn medium={4}>
                <SearchResult
                  key={data.id}
                  first={{
                    id: data.id,
                    name: data.name,
                    surname: data.surname || '',
                    to: firstRoute + '/' + data.id
                  }}
                  second={{
                    name:
                      tableName === 'competitorSearchResults'
                        ? data.Club.name
                        : null,
                    id:
                      tableName === 'competitorSearchResults'
                        ? data.Club.id
                        : null,
                    to:
                      tableName === 'competitorSearchResults'
                        ? secondRoute + '/' + data.Club.id
                        : null
                  }}
                />
              </GridColumn>
            ))
        ) : (
          <GridColumn medium={4}>
            <span>Nema rezultata</span>
          </GridColumn>
        )}
        <LinkWrapper><Link to={firstRoute}>Prikaži više</Link></LinkWrapper>
      </>
    );
  };

  return (
    <Grid>
      <GridColumn medium={4}>
        <Wrapper title='NATJECATELJI'>
          <Grid>
            {searchDataToJsx(
              'competitorSearchResults',
              '/competitors',
              '/clubs'
            )}
          </Grid>
        </Wrapper>
      </GridColumn>
      <GridColumn medium={4}>
        <Wrapper title='UTRKE'>
          <Grid>{searchDataToJsx('raceSearchResults', '/races')}</Grid>
        </Wrapper>
      </GridColumn>
      <GridColumn medium={4}>
        <Wrapper title='KLUBOVI'>
          <Grid>{searchDataToJsx('clubSearchResults', '/clubs')}</Grid>
        </Wrapper>
      </GridColumn>
    </Grid>
  );
};

export default SearchResults;
