import React, { useState, useEffect } from 'react';
import { createRace } from '../../actions';
import { useStateValue } from '../../contexts/AppContext';
import RaceForm from '../../components/Forms/RaceForm';
import { getApiData } from '../../actions';
import Page from '../../components/Page';

const RaceNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    getApiData('clubs', '/api/all_clubs', dispatch);
    getApiData('raceTypes', '/api/raceType', dispatch);
    getApiData('categories', '/api/category', dispatch);
    getApiData('ranks', '/api/rank', dispatch);
    getApiData('cups', '/api/cup', dispatch);
  }, []);
  const onSubmit = async formProps => {
    setSubmitting(true);
    await Promise.all(
      formProps.categoryId.map(async categoryId => {
        await createRace({ ...formProps, categoryId }, dispatch);
      })
    );
    setSubmitting(false);
    dispatch({
      type: 'redirect',
      payload: '/races'
    });
  };
  return (
    <Page title={'Nova utrka'}>
      <RaceForm
        onSubmit={onSubmit}
        submitting={submitting}
        clubOptions={state.apiData['clubs']}
        raceTypeOptions={state.apiData['raceTypes']}
        categoryOptions={state.apiData['categories']}
        rankOptions={state.apiData['ranks']}
        cupOptions={state.apiData['cups']}
        multipleCategory={true}
      />
    </Page>
  );
};

export default RaceNew;
