import React, { useState, useEffect } from 'react';
import PageHeader from '@atlaskit/page-header';
import Page from '@atlaskit/page';
import { createHeadCoach } from '../../actions';
import { useStateValue } from '../../contexts/AppContext';
import ModeratorForm from '../../components/Forms/ModeratorForm';

const ModeratorNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  
  const onSubmit = async formProps => {
    setSubmitting(true);
    createHeadCoach(formProps, dispatch);
    setSubmitting(false);
  };
  return (
    <Page>
      <PageHeader>Novi trener reprezentacije</PageHeader>
      <ModeratorForm
        onSubmit={onSubmit}
        submitting={submitting}
        clubOptions={-1}
      />
    </Page>
  );
};

export default ModeratorNew;
