import React, { useState, useEffect } from 'react';
import { BreadcrumbsStateless, BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { withRouter } from 'react-router-dom';

const Breadcrumbs = ({ title, location, history }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expand = e => {
    e.preventDefault();
    setIsExpanded(true);
  };

  return (
    <BreadcrumbsStateless isExpanded={isExpanded} onExpand={e => expand(e)}>
      {location.pathname.split('/').map((l, index) => {
        if (l.length === 0) return null;
        return (
          <BreadcrumbsItem
            key={l}
            onClick={() =>
              history.push(
                location.pathname
                  .split('/')
                  .slice(0, index + 1)
                  .join('/')
              )
            }
            text={isNaN(l) ? l.toUpperCase() : title}
          />
        );
      })}
    </BreadcrumbsStateless>
  );
};

export default withRouter(props => <Breadcrumbs {...props} />);
