import React from "react";
import TextField from "@atlaskit/textfield";
import Button, { ButtonGroup } from "@atlaskit/button";
import Form, { Field, FormFooter } from "@atlaskit/form";
import { CreatableSelect } from "@atlaskit/select";
import { useStateValue } from "../../contexts/AppContext";

const format = data =>
  data.map(value => {
    return {
      label: value.name,
      value: value.id
    };
  });

const fields = [
  {
    name: "username",
    label: "Korisničko ime",
    isRequired: true
  },
  {
    name: "password",
    label: "Lozinka",
    type: "password",
    isRequired: true
  }
];
const ModeratorForm = ({
  onSubmit,
  submitting,
  initialFormProps = {},
  clubOptions = [],
  submitButtonText = "Dodaj"
}) => {
  const [state, dispatch] = useStateValue();
  return (
    <Form onSubmit={async formProps => await onSubmit(formProps)}>
      {({ formProps = initialFormProps }) => (
        <form {...formProps}>
          {fields.map((field, index) => (
            <Field
              key={index}
              name={field.name}
              defaultValue={initialFormProps[field.name]}
              isRequired={field.isRequired}
              label={field.label}
            >
              {({ fieldProps, error }) => (
                <>
                  <TextField
                    autoComplete="off"
                    {...fieldProps}
                    type={field.type || "text"}
                  />
                </>
              )}
            </Field>
          ))}
          {clubOptions !== -1 && (
            <Field label="Klub" name="clubs">
              {({ fieldProps, error }) => (
                <>
                  <CreatableSelect
                    isClearable
                    allowCreateWhileLoading
                    {...fieldProps}
                    options={format(clubOptions)}
                    placeholder="Odaberite klub"
                    onCreateOption={() =>
                      dispatch({
                        type: "redirect",
                        payload: "/clubs/new"
                      })
                    }
                  />
                </>
              )}
            </Field>
          )}

          <FormFooter>
            <ButtonGroup>
              <Button type="submit" appearance="primary" isLoading={submitting}>
                {submitButtonText}
              </Button>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default ModeratorForm;
