import React, { useState, useEffect } from "react";
import Form, { Field } from "@atlaskit/form";
import Button from "@atlaskit/button";
import TextField from "@atlaskit/textfield";
import Select from "@atlaskit/select";
import { createMeasure, getApiData } from "../../actions";
import DatePicker from "react-date-picker";
import { useStateValue } from "../../contexts/AppContext";
import ReactTable from "react-table";
import moment from "moment";
import axios from "axios";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import Wrapper from "../../components/Wrapper";
import { useCookies } from "react-cookie";

const options = [
  { value: 1, label: "200m / 1500m" },
  { value: 2, label: "400m / 3000m" }
];

const submitForm = async (data, setSubmitting, apiRoute, tableName, dispatch) => {
  setSubmitting(true);
  try {
    if (
      data.time1 &&
      data.time2 &&
      data.date &&
      data.id
    ) {
      const time1 = data.time1.split(":");
      const time2 = data.time2.split(":");
      await createMeasure({
        time1: parseInt(time1[0], 10) * 60 + parseInt(time1[1], 10),
        time2: parseInt(time2[0], 10) * 60 + parseInt(time2[1], 10),
        date: data.date,
        competitorId: parseInt(data.id, 10)
      });
      await getApiData(tableName, apiRoute, dispatch);
    }
  } catch (e) {
    console.log(e);
  }

  setSubmitting(false);
};

const CompetitorMeasures = ({ id }) => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  const apiRoute = `/api/measure?competitorId=${id}`;
  const tableName = `competitorMeasure-${id}`;
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    getApiData(tableName, apiRoute, dispatch);
  }, []);

  return (
    <>
      {state.apiData[tableName] && (
        <Wrapper>
          <ReactTable
            showPagination={state.apiData[tableName].length > 20}
            data={state.apiData[tableName]}
            columns={[
              {
                Header: "Datum",
                accessor: "date",
                maxWidth: 300,
                Cell: row => <span>{moment(row.value).format("DD.MM.YYYY.")}</span>
              },
              {
                Header: "Trčanje vrijeme",
                accessor: "time2",
                maxWidth: 300,
                Cell: row =>  <span>{moment.utc(row.original.time2 * 1000).format("mm:ss")}</span>
              },
              {
                Header: "Plivanje vrijeme",
                accessor: "time1",
                maxWidth: 300,
                Cell: row =>  <span>{moment.utc(row.original.time1 * 1000).format("mm:ss")}</span>
              },
              {
                Header: "Izbriši",
                accessor: "delete",
                maxWidth: 100,
                Cell: row => (
                  <Button
                    onClick={async () => {
                      await axios.delete(apiRoute, { params: { id: row.original.id } });
                      getApiData(tableName, apiRoute, dispatch);
                    }}
                  >
                <CrossCircleIcon />
              </Button>
                ),
                show: cookies.token === "1" || cookies.token === "3",
              }
            ]}
            defaultSorted={[
              {
                id: "date",
                desc: true
              }
            ]}
            defaultPageSize={5}
            className="-striped -highlight"
          />
        </Wrapper>
      )}

      <h3>Unos novog testiranja</h3>
      <Form
        onSubmit={data => submitForm({ ...data, id }, setSubmitting, apiRoute, tableName, dispatch)}
      >
        {({ formProps }) => (
          <form {...formProps}>
            <Field name="time1" defaultValue="" label="Plivanje" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field name="time2" defaultValue="" label="Trčanje" isRequired>
              {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            <Field
              name="date"
              isRequired={true}
              label="Datum testiranja"
              defaultValue={new Date()}
            >
              {({ fieldProps }) => (
                <>
                  <br />
                  <DatePicker {...fieldProps} />
                </>
              )}
            </Field>
            <br />
            <Button type="submit" appearance="primary" isLoading={submitting}>
              Submit
            </Button>
          </form>
        )}
      </Form>
    </>
  );
};

export default CompetitorMeasures;
