import axios from "axios";

export const fetchUser = async dispatch => {
  const res = await axios.get("/api/current_user");
  dispatch({ type: "userData", payload: res.data });
};

export const fetchClubs = async () => {
  const res = await axios.get("/api/clubs");
  const data = res.data;
  return data;
};

export const logOut = async () => {
  await axios.get("/api/logout");
  window.location = "/login";
  return true;
};

export const logIn = async (formProps, dispatch, setCookie) => {
  const res = await axios.post("/api/login", {
    ...formProps
  });
  if (
    res.data.message &&
    res.status === 200 &&
    res.data.isAuthenticated === true
  ) {
    setCookie("token", res.data.token);
    setCookie("clubs", res.data.clubs);
    dispatch({
      type: "redirect",
      payload: "/dashboard"
    });
  } else {
    dispatch({
      type: "error",
      payload: res.data.error
    });
  }
  return res;
};

export const createRegistration = async (formProps, dispatch) => {
  const res = await axios.put("/api/startList", { ...formProps });
  dispatch({
    type: "redirect",
    payload: `/races/${formProps.raceId}`
  });
  return res;
};

export const createClub = async (formProps, dispatch) => {
  const res = await axios.put("/api/club", { ...formProps });
  dispatch({
    type: "redirect",
    payload: "/clubs"
  });
  return res;
};

export const createModerator = async (formProps, dispatch) => {
  try {
    const res = await axios.put("/api/moderator", {
      ...formProps,
      clubs: [formProps.clubs]
    });
    dispatch({
      type: "redirect",
      payload: "/moderators"
    });
    return res;
  } catch (err) {
    console.log("Error");
  }
};

export const createHeadCoach = async (formProps, dispatch) => {
  try {
    const res = await axios.put("/api/moderator3", {
      ...formProps,
      clubs: [formProps.clubs]
    });
    dispatch({
      type: "redirect",
      payload: "/moderators"
    });
    return res;
  } catch (err) {
    console.log("Error");
  }
};

export const createRace = async (formProps, dispatch) => {
  let res = null;
  try {
    res = await axios.put("/api/race", { ...formProps });
  } catch (err) {
    console.log(err);
  }
  return res;
};

export const createRaceType = async (formProps, dispatch) => {
  let res = null;
  try {
    res = await axios.put("/api/raceType", { ...formProps });
    dispatch({
      type: "redirect",
      payload: "/raceTypes"
    });
  } catch (err) {
    console.log(err);
  }
  return res;
};

export const createRank = async (formProps, dispatch) => {
  let res = null;
  try {
    res = await axios.put("/api/rank", { ...formProps });
    dispatch({
      type: "redirect",
      payload: "/ranks"
    });
  } catch (err) {
    console.log(err);
  }
  return res;
};

export const createClubData = async (formProps) => {
  let res = null;
  try {
    res = await axios.post("/api/club-points", { ...formProps });
  } catch (err) {
    console.log(err);
  }
  return res;
};


export const createCategory = async (formProps, dispatch) => {
  let res = null;
  try {
    res = await axios.put("/api/category", { ...formProps });
    dispatch({
      type: "redirect",
      payload: "/categories"
    });
  } catch (err) {
    console.log(err);
  }
  return res;
};

export const createCompetitor = async (
  formProps,
  dispatch,
  redirect = true,
  redirectRoute = "/competitors"
) => {
  const res = await axios.put("/api/competitor", { ...formProps });
  if (res.status === 200) {
    if (redirect) {
      dispatch({
        type: "redirect",
        payload: redirectRoute
      });
    }
  } else {
    console.log("Error");
  }
  return res;
};

export const getData = async (url, id) => {
  const res = await axios.get(url, { params: { id } });
  return res;
};

export const updateData = async (url, formProps, dispatch, redirect = null) => {
  const res = await axios.post(url, { ...formProps });
  if (res.status === 200) {
    if (redirect) {
      dispatch({
        type: "redirect",
        payload: `${redirect}` + (formProps.id ? `/${formProps.id}` : "")
      });
    }
  } else {
    console.log("Error");
  }

  return res;
};

export const getApiData = async (tableName, url, dispatch, params = {}) => {
  const res = await axios.get(url, { params });
  const data = res.data;
  dispatch({
    type: "apiData",
    payload: {
      tableName,
      data
    }
  });
};

export const deleteData = async (url, params, dispatch, redirect = null) => {
  if (!(params === Object(params))) {
    params = { id: params };
  }
  const res = await axios.delete(url, { params });
  if (res.status === 200) {
    if (redirect) {
      dispatch({
        type: "redirect",
        payload: redirect
      });
    }
  } else {
    console.log("Error");
  }

  return res;
};

export const addCompetitorToClub = async params => {
  const res = await axios.post("/api/add_competitor", { ...params });
  if (res.status === 200) {
    console.log("Ok");
  } else {
    console.log("Error");
  }

  return res;
};

export const deleteLicense = async (id, dispatch) => {
  const res = await axios.delete("/api/license", {
    params: { id }
  });
  if (res.status === 200) {
    dispatch({
      type: "removeLicense",
      payload: { id }
    });
  } else {
    console.log("Error");
  }
};

export const approveLicense = async (id, dispatch) => {
  const res = await axios.post("/api/license", {
    id
  });
  if (res.status === 200) {
    dispatch({
      type: "removeLicense",
      payload: { id }
    });
  } else {
    console.log("Error");
  }
};

export const createMeasure = async formProps => {
  let res;
  try {
    res = await axios.post("/api/measure", { ...formProps });
  } catch (err) {
    console.log(err);
  }
  return res;
};

