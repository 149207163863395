import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

export default function(clubId) {
  const [isClubAdmin, setIsClubAdmin] = useState(false);
  const [cookies] = useCookies(['token', 'clubs']);

  useEffect(() => {
    if(cookies && cookies['clubs'] && parseInt(cookies['clubs'][0]) === parseInt(clubId)) {
      setIsClubAdmin(true);
    } else {
      setIsClubAdmin(false);
    }
  }, [cookies.clubs]);

  return isClubAdmin;
}