import React, { useState, useEffect } from "react";
import RecentIcon from "@atlaskit/icon/glyph/recent";
import EmojiCustomIcon from "@atlaskit/icon/glyph/emoji/custom";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import TrashIcon from "@atlaskit/icon/glyph/trash";
import { Button } from "@atlaskit/button/components/Button";
import axios from "axios";
import { useCookies } from "react-cookie";

const StartListStatus = ({ status = { value: 0 }, id, competitorClubId }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token", "clubs"]);
  const [localStatus, setLocalStatus] = useState(status);

  switch (localStatus.value) {
    case 0:
      if (cookies.token === "1") {
        return (
          <>
            <Button
              onClick={async () => {
                const result = await axios.post("/api/startList", {
                  id: id,
                });
                if (result.status === 200) {
                  setLocalStatus({ ...localStatus, value: 1 });
                }
              }}
            >
              <EmojiCustomIcon />
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={async () => {
                const result = await axios.delete("/api/startList", {
                  params: {
                    id: id,
                  },
                });
                if (result.status === 200) {
                  setLocalStatus({ ...localStatus, value: 2 });
                }
              }}
            >
              <TrashIcon />
            </Button>
          </>
        );
      } else if (cookies.token === "2") {
        return (
          <>
            <RecentIcon
              size="large"
              style={{ marginLeft: "6px" }}
              primaryColor="orange"
            />
            {cookies.clubs && cookies.clubs.includes(competitorClubId) && (
              <Button
                style={{ marginLeft: 8 }}
                onClick={async () => {
                  const result = await axios.delete("/api/startList", {
                    params: {
                      id: id,
                    },
                  });
                  if (result.status === 200) {
                    setLocalStatus({ ...localStatus, value: 2 });
                  }
                }}
              >
                <TrashIcon />
              </Button>
            )}
          </>
        );
      } else {
        return (
          <RecentIcon
            size="large"
            style={{ marginLeft: "6px" }}
            primaryColor="orange"
          />
        );
      }
    case 1:
      return (
        <>
          <CheckCircleIcon
            size="large"
            style={{ marginLeft: "6px" }}
            primaryColor="green"
          />
          {cookies.token === "1" && <Button
            style={{ marginLeft: 8 }}
            onClick={async () => {
              const result = await axios.delete("/api/startList", {
                params: {
                  id: id,
                },
              });
              if (result.status === 200) {
                setLocalStatus({ ...localStatus, value: 2 });
              }
            }}
          >
            <TrashIcon />
          </Button>}
        </>
      );
    case 2:
      return (
        <TrashIcon
          size="large"
          style={{ marginLeft: "6px" }}
          primaryColor="red"
        />
      );

    default:
      return <p>Test</p>;
  }
};

export default StartListStatus;
