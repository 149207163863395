import React, { useEffect } from 'react';
import ModalDialog, {
  ModalFooter,
  ModalTransition
} from '@atlaskit/modal-dialog';
import { CreatableSelect } from '@atlaskit/select';
import { useStateValue } from '../../contexts/AppContext';
import { getApiData } from '../../actions';
import Button from '@atlaskit/button';
import Form, { Field } from '@atlaskit/form';
import LinkButton from '../LinkButton';

const formatCompetitorOptions = competitorOptions => {
  return competitorOptions
    ? competitorOptions.map(competitor => {
        return {
          label: `${competitor.name} ${competitor.surname}`,
          value: competitor.id
        };
      })
    : [];
};

const helperFunctions = async dispatch => {
  await getApiData(
    'availableCompetitors',
    '/api/competitor?clubId=-1',
    dispatch
  );
};

const AddCompetitor = ({ setIsOpen, onSubmit }) => {
  const [state, dispatch] = useStateValue();
  useEffect(() => {
    helperFunctions(dispatch);
  }, []);

  const footer = props => (
    <ModalFooter showKeyline={props.showKeyline}>
      <LinkButton appearance='secondary' to='/competitors/new'>
        Novi
      </LinkButton>
      <Button appearance='primary' type='submit'>
        Dodaj
      </Button>
    </ModalFooter>
  );
  return (
    <ModalTransition>
      <ModalDialog
        heading='Dodavanje natjecatelja'
        onClose={() => setIsOpen(false)}
        components={{
          Container: ({ children, className }) => (
            <Form onSubmit={async formProps => await onSubmit(formProps)}>
              {({ formProps }) => (
                <form {...formProps} className={className}>
                  {children}
                </form>
              )}
            </Form>
          ),
          Footer: footer
        }}
      >
        <Field name='competitor'>
          {({ fieldProps, error }) => (
            <>
              <CreatableSelect
                {...fieldProps}
                options={formatCompetitorOptions(
                  state.apiData.availableCompetitors
                )}
                placeholder='Odaberite natjecatelja'
              />
            </>
          )}
        </Field>
        <br />
        <br />
        <br />
      </ModalDialog>
    </ModalTransition>
  );
};

export default AddCompetitor;
