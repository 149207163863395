import React, { useState, useEffect } from 'react';
import PageHeader from '@atlaskit/page-header';
import Page from '@atlaskit/page';
import { createModerator } from '../../actions';
import { useStateValue } from '../../contexts/AppContext';
import ModeratorForm from '../../components/Forms/ModeratorForm';
import {getApiData} from '../../actions';

const ModeratorNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    getApiData('clubs', '/api/all_clubs', dispatch);
  }, []);
  const onSubmit = async formProps => {
    setSubmitting(true);
    createModerator(formProps, dispatch);
    setSubmitting(false);
  };
  return (
    <Page>
      <PageHeader>Novi moderator</PageHeader>
      <ModeratorForm
        onSubmit={onSubmit}
        submitting={submitting}
        clubOptions={state.apiData['clubs']}
      />
    </Page>
  );
};

export default ModeratorNew;
