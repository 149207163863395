import React from 'react';
import TextField from '@atlaskit/textfield';
import styled from 'styled-components';
import { useStateValue } from '../contexts/AppContext';
import { logIn } from '../actions';
import { useCookies } from 'react-cookie';
import Page from '../components/Page';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, {
  Field,
  FormFooter
} from '@atlaskit/form';

const BaseForm = styled.div`
  margin-left: 20%;
  margin-right: 20%;
`;

const Login = () => {
  const [state, dispatch] = useStateValue();
  const [cookies, setCookie] = useCookies(['token']);
  const onSubmit = async formProps => {
    await logIn(formProps, dispatch, setCookie);
  };

  return (
    <Page title='HRVATSKI TRIATLON SAVEZ'>
      <BaseForm>
        <Form
          onSubmit={data => {
            onSubmit(data);
          }}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <Field
                name='username'
                label='Korisničko ime'
                isRequired
                defaultValue=''
              >
                {({ fieldProps }) => (
                  <>
                    <TextField autoComplete='off' {...fieldProps} />
                  </>
                )}
              </Field>
              <Field name='password' label='Lozinka' defaultValue='' isRequired>
                {({ fieldProps }) => (
                  <>
                    <TextField type='password' {...fieldProps} />
                  </>
                )}
              </Field>
              <FormFooter>
                <ButtonGroup>
                  <Button
                    type='submit'
                    appearance='primary'
                    isLoading={submitting}
                  >
                    Prijava
                  </Button>
                </ButtonGroup>
              </FormFooter>
            </form>
          )}
        </Form>
      </BaseForm>
    </Page>
  );
};

export default Login;
