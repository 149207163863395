import React, { useState, useEffect } from 'react';
import Spinner from '@atlaskit/spinner';
import { getData, updateData, getApiData } from '../../actions';
import RaceForm from '../../components/Forms/RaceForm';
import { useStateValue } from '../../contexts/AppContext';
import Page from '../../components/Page';
import { DeleteModal } from '../../components/Modals';

const helperFunctions = async (id, setRaceData, setIsLoading, dispatch) => {
  const data = await Promise.all([
    await getData('/api/race', id),
    await getApiData('availableClubs', '/api/all_clubs_available', dispatch),
    await getApiData('clubs', '/api/all_clubs', dispatch),
    await getApiData('raceTypes', '/api/raceType', dispatch),
    await getApiData('categories', '/api/category', dispatch),
    await getApiData('ranks', '/api/rank', dispatch),
    await getApiData('cups', '/api/cup', dispatch)
  ]);
  setRaceData(data[0].data);
  setIsLoading(false);
};

const RaceSettings = props => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [raceData, setRaceData] = useState({});
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    helperFunctions(id, setRaceData, setIsLoading, dispatch);
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async formProps => {
    setSubmitting(true);
    console.log(formProps);
    await updateData('/api/race', { ...formProps, id }, dispatch, '/races');
    setSubmitting(false);
  };

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <Page
        title={raceData.name}
        actions={[
          {
            label: 'Obriši',
            appearance: 'danger',
            onClick: () => setIsOpen(true)
          }
        ]}
      >
        <h3>Postavke</h3>
        <RaceForm
          onSubmit={onSubmit}
          submitting={submitting}
          initialFormProps={raceData}
          submitButtonText='Promijeni'
          clubOptions={state.apiData['clubs']}
          raceTypeOptions={state.apiData['raceTypes']}
          categoryOptions={state.apiData['categories']}
          rankOptions={state.apiData['ranks']}
          cupOptions={state.apiData['cups']}
        />
        {isOpen && (
          <DeleteModal
            onClose={() => setIsOpen(false)}
            infoText='Upišite ime utrke koju ćete obrisati'
            value={raceData.name}
            deleteRoute='/api/race'
            id={id}
            redirect='/races'
          />
        )}
      </Page>
    );
  }
};

export default RaceSettings;
