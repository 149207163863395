import React from 'react';
import TextField from '@atlaskit/textfield';
import Button, { ButtonGroup } from '@atlaskit/button';
import Form, { Field, FormFooter } from '@atlaskit/form';
import Select, { CreatableSelect } from '@atlaskit/select';
import { useStateValue } from '../../contexts/AppContext';
import { DatePicker } from '@atlaskit/datetime-picker';

const defaultValue = (data, value) => {
  data = format(data).filter(option => option.value == value);
  if (data.length === 1) {
    return data[0];
  }
  return null;
};

const format = data =>
  data.map(value => {
    return {
      label: value.name + (value.sex ? ' ' + value.sex : ''),
      value: value.id
    };
  });

const RaceForm = ({
  onSubmit,
  submitting,
  initialFormProps = {},
  clubOptions = [],
  raceTypeOptions = [],
  categoryOptions = [],
  rankOptions = [],
  cupOptions = [],
  multipleCategory = false,
  submitButtonText = 'Dodaj'
}) => {
  const [state, dispatch] = useStateValue();
  return (
    <Form onSubmit={async formProps => await onSubmit(formProps)}>
      {({ formProps = initialFormProps }) => (
        <form {...formProps}>
          <Field
            name='name'
            defaultValue={initialFormProps['name']}
            isRequired={true}
            label='Ime utrke'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='location'
            defaultValue={initialFormProps['location']}
            isRequired={true}
            label='Lokacija'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='date'
            isRequired={true}
            label='Datum i vrijeme'
            defaultValue={initialFormProps.date}
          >
            {({ fieldProps, error }) => (
              <>
                <DatePicker {...fieldProps} />
              </>
            )}
          </Field>
          <Field
            label='Rang utrke'
            name='rankId'
            defaultValue={defaultValue(rankOptions, initialFormProps['rankId'])}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  {...fieldProps}
                  options={format(rankOptions)}
                  placeholder='Odaberite rang utrke'
                />
              </>
            )}
          </Field>
          <Field
            label='Vrsta utrke'
            name='raceTypeId'
            defaultValue={defaultValue(
              raceTypeOptions,
              initialFormProps['raceTypeId']
            )}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  {...fieldProps}
                  options={format(raceTypeOptions)}
                  placeholder='Odaberite vrstu utrke'
                />
              </>
            )}
          </Field>
          <Field
            label='Kategorija natjecatelja'
            name='categoryId'
            defaultValue={defaultValue(
              categoryOptions,
              initialFormProps['categoryId']
            )}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  {...fieldProps}
                  isMulti={multipleCategory}
                  options={format(categoryOptions)}
                  placeholder='Odaberite kategoriju natjecatelja'
                />
              </>
            )}
          </Field>
          <Field
            label='Kup natjecatelja'
            name='cupId'
            defaultValue={defaultValue(cupOptions, initialFormProps['cupId'])}
          >
            {({ fieldProps, error }) => (
              <>
                <Select
                  {...fieldProps}
                  options={format(cupOptions)}
                  placeholder='Odaberite kup natjecatelja'
                />
              </>
            )}
          </Field>
          <Field
            label='Organizator'
            name='clubId'
            defaultValue={defaultValue(clubOptions, initialFormProps['clubId'])}
          >
            {({ fieldProps, error }) => (
              <>
                <CreatableSelect
                  allowCreateWhileLoading
                  {...fieldProps}
                  options={format(clubOptions)}
                  placeholder='Odaberite klub'
                  onCreateOption={() =>
                    dispatch({
                      type: 'redirect',
                      payload: '/clubs/new'
                    })
                  }
                />
              </>
            )}
          </Field>
          <Field
            name='pdfUrl'
            defaultValue={initialFormProps['pdfUrl']}
            isRequired={false}
            label='URL Raspis utrke'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='registerUrl'
            defaultValue={initialFormProps['registerUrl']}
            isRequired={false}
            label='URL za registraciju'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='startListUrl'
            defaultValue={initialFormProps['startListUrl']}
            isRequired={false}
            label='URL startne liste'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='technicalDelegate'
            defaultValue={initialFormProps['technicalDelegate']}
            isRequired={false}
            label='Tehnički delegat'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='competitionJury'
            defaultValue={initialFormProps['competitionJury']}
            isRequired={false}
            label='Competition jury'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <Field
            name='comment'
            defaultValue={initialFormProps['comment']}
            isRequired={false}
            label='Komentar'
          >
            {({ fieldProps, error }) => (
              <>
                <TextField autoComplete='off' {...fieldProps} type='text' />
              </>
            )}
          </Field>
          <FormFooter>
            <ButtonGroup>
              <Button type='submit' appearance='primary' isLoading={submitting}>
                {submitButtonText}
              </Button>
            </ButtonGroup>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default RaceForm;
