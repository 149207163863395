import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { Link } from "react-router-dom";
import moment from "moment";
import { useStateValue } from "../../contexts/AppContext";
import { getApiData } from "../../actions";
import ChipList from "../../components/ChipList";
import formatOptions from "../../helpers/formatOptions";
import ReactTable from "react-table";
import { Checkbox } from "@atlaskit/checkbox";

const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2019 },
  (_, index) => {
    return { value: 2019 + index + 1, label: 2019 + index + 1 };
  }
);

const getDates = (apiData) => {
  if (!apiData) {
    return [];
  }
  return apiData.reduce((acc, current) => {
    const date = moment(current.date).format("DD.MM.YYYY.")
    if (!acc.includes(date)) {
      acc.push(date);
    }
    return acc;
  }, []);
}

const Measures = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [categoryId, setCategoryId] = useState(null);
  const [state, dispatch] = useStateValue();
  const [selectedDates, setSelectedDates] = useState([]);
  const [onlyMax, setOnlyMax] = useState(false);
  const tableName = "measures";

  useEffect(() => {
    getApiData("categories", "/api/category", dispatch);
    getApiData("cups", "/api/cup", dispatch);
  }, []);

  const getResults = () => {
    getApiData("measures", "/api/measure", dispatch, {
      year,
      categoryId
    });
  };

  useEffect(() => {
    getResults();
  }, [year, categoryId]);

  const formatData = (data) => {
    data = data.filter((data) => {
      if (selectedDates.length > 0) {
        return selectedDates.includes(moment(data.date).format("DD.MM.YYYY."));
      }
      return true;
    });
    if (onlyMax) {
      data = data.
        sort((m1, m2) => m1.competitorId === m2.competitorId ? m2.totalPoints - m1.totalPoints : m1.competitorId - m2.competitorId).
        reduce((acc, current) => {
          if (!acc.map((a) => a.competitorId).includes(current.competitorId)) {
            acc.push(current);
          }
          return acc;
        }, []);
    }

    data = data.sort((a, b) => a.totalPoints - b.totalPoints);

    return data;
  }
 
  return (
    <Page
      title="Testiranje sportaša"
      search={
        <>
          <br />
          <ChipList
            defaultValue={new Date().getFullYear()}
            options={yearOptions}
            onChange={option => {
              if (option) {
                setYear(option.value);
              }
            }}
          />
          <br />
          {state.apiData["categories"] && (
            <ChipList
              options={formatOptions(state.apiData["categories"]).filter(
                category => {
                  return !category.label.toLowerCase().includes("etnici");
                }
              )}
              onChange={option => {
                if (option) {
                  setCategoryId(option.value);
                }
              }}
            />
          )}
          <br />
          {state.apiData[tableName] && (
            <ChipList
              multiple
              options={getDates(state.apiData[tableName]).map((date) => ({
                label: date,
                value: date
              }))}
              onValuesChange={values => setSelectedDates(values)}
            />
          )}
          <br />
          <Checkbox
            isChecked={onlyMax}
            label="Samo najveći broj bodova"
            onChange={event => {
              setOnlyMax(event.target.checked);
            }}
          />
        </>
      }
    >
      {state.apiData[tableName] && (
        <ReactTable
          showPagination={state.apiData[tableName].length > 20}
          data={formatData(state.apiData[tableName])}
          columns={[
            {
              Header: "Natjecatelj",
              accessor: "competitorId",
              maxWidth: 300,
              Cell: row => (
                <Link to={`/competitors/${row.original.competitorId}`}>{row.original.Competitor.name} {row.original.Competitor.surname}</Link>
              )
            },
            {
              Header: "Datum mjerenja",
              accessor: "date",
              maxWidth: 150,
              Cell: row => (
                <span>{moment(row.value).format("DD.MM.YYYY.")}</span>
              )
            },
            {
              Header: "Ukupni bodovi",
              accessor: "totalPoints",
              maxWidth: 150
            },
            {
              Header: "Trčanje bodovi",
              accessor: "runPoints",
              maxWidth: 150
            },
            {
              Header: "Plivanje bodovi",
              accessor: "swimPoints",
              maxWidth: 150
            },
            {
              Header: "Trčanje vrijeme",
              accessor: "time2",
              maxWidth: 150,
              Cell: row =>  <span>{moment.utc(row.original.time2 * 1000).format("mm:ss")}</span>
            },
            {
              Header: "Plivanje vrijeme",
              accessor: "time1",
              maxWidth: 150,
              Cell: row =>  <span>{moment.utc(row.original.time1 * 1000).format("mm:ss")}</span>
            },
          ]}
          defaultSorted={[
            {
              id: "id",
              desc: true
            }
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
        />
      )}
    </Page>
  );
};

export default Measures;
