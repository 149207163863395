import React, { useRef, useEffect, useState } from 'react';
import TextField from '@atlaskit/textfield';
import Page from '../../components/Page';
import { getApiData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import filterData from "../../helpers/filterData";
import delayedCallback from "../../helpers/delayedCallback";
import Wrapper from "../../components/Wrapper";


const Clubs = () => {
  const clubs = useRef(null);
  const [state, dispatch] = useStateValue();
  const tableName = "clubs";
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    getApiData(tableName, "/api/all_clubs_extended", dispatch);
  }, []);

  const searchInputChange = event => {
    event.persist();
    delayedCallback(event, setFilter);
  };

  return (
    <Page
      title='Klubovi'
      actions={[{ label: 'Dodaj novi klub', href: '/clubs/new', admin: true }]}
      search={
        <TextField
        onChange={searchInputChange}
        placeholder="Filtriraj klubove"
        />
      }
    >
       {state.apiData[tableName] && (
         <Wrapper>
          <ReactTable
            showPagination={state.apiData[tableName].length > 20}
            data={filterData(state.apiData[tableName], filter)}
            columns={[
              {
                Header: "Id",
                accessor: "id",
                maxWidth: 50
              },
              {
                Header: "Naziv",
                accessor: "name",
                maxWidth: 300,
                Cell: row => (
                  <Link to={`/clubs/${row.original.id}`}>{row.value}</Link>
                )
              },
              {
                Header: "Mjesto",
                accessor: "place",
                maxWidth: 100
              },
              {
                Header: "Adresa",
                accessor: "address",
                maxWidth: 200
              },
              {
                Header: "Predsjednik",
                accessor: "president",
                maxWidth: 200
              },
              {
                Header: "Kontakt",
                accessor: "contact",
                maxWidth: 200
              },
              {
                Header: "Email",
                accessor: "email",
                maxWidth: 200
              },
              {
                Header: "Web",
                accessor: "president",
                maxWidth: 200
              }
            ]}
            defaultSorted={[
              {
                id: "id",
                desc: false
              }
            ]}
            defaultPageSize={20}
            className="-striped -highlight"
          />
        </Wrapper>
       )}
    </Page>
  );
};

export default Clubs;
