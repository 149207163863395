import React, { useEffect, useState } from "react";
import TextField from "@atlaskit/textfield";
import Button, { ButtonGroup } from "@atlaskit/button";
import Form, { Field, FormFooter } from "@atlaskit/form";
import Select, { CreatableSelect } from "@atlaskit/select";
import { getApiData, createClubData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import DocumentIcon from "@atlaskit/icon/glyph/document";

const year = new Date().getFullYear() - 1;

const ClubData = ({ id }) => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    getApiData(
      "initialFormProps",
      `/api/club-points?year=${year}&clubId=${id}`,
      dispatch
    );
    getApiData("clubCompetitors", `/api/competitor?clubId=${id}`, dispatch);
  }, []);

  if (!state.apiData["clubCompetitors"]) {
    return <p>Loading...</p>;
  }
  const initialFormProps = state.apiData["initialFormProps"] || {};

  const onSubmit = async (data) => {
    setSubmitting(true);
    try {
      const res = await createClubData({ ...data, clubId: id });
      if (res === null) {
        dispatch({
          type: "error",
          payload: "Something went wrong",
        });
      } else {
        getApiData(
          "initialFormProps",
          `/api/club-points?year=${year}&clubId=${id}`,
          dispatch
        );
      }
    } catch (e) {
      dispatch({
        type: "error",
        payload: "Something went wrong",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const competitors = state.apiData["clubCompetitors"].map((competitor) => ({
    label: competitor.name + " " + competitor.surname,
    value: competitor.id,
  }));

  const fakeCompetitors = competitors.map((competitor) => ({
    label: competitor.label,
    value: competitor.label,
  }));

  const fields = [
    {
      name: "year",
      label: "GODINA",
      isSelect: true,
      isMulti: false,
      isRequired: true,
      defaultValue: year,
      onChange: (value) => {
        getApiData(
          "initialFormProps",
          `/api/club-points?year=2020&clubId=${id}`,
          dispatch
        );
      },
      options: [{ label: year, value: year }],
    },
    {
      name: "absItuPointRankingPoint",
      label:
        "2.1.1. Bodovi sportaša evidentirani finalnom ITU POINT ljestvicom tekućoj godini",

      isSelect: false,
    },
    {
      name: "absItuPointRankingPointCompetitors",
      label: "Natjecatelji za koje se odnosi prethodni bodovi",

      isSelect: true,
      options: competitors,
    },
    {
      name: "absEtuPointRankingPoint",
      label:
        "2.1.2. Bodovi sportaša evidentirani finalnom ETU POINT ljestvicom u tekućoj godini",

      isSelect: false,
    },
    {
      name: "absEtuPointRankingPointCompetitors",
      label: "Natjecatelji za koje se odnosi prethodni bodovi",

      isSelect: true,
      options: competitors,
    },
    {
      name: "restEtuPointRankingPoint",
      label:
        "2.2.2. Redoslijed na finalnoj ETU POINT ljestvici u tekućoj godini",

      isSelect: false,
    },
    {
      name: "restEtuPointRankingPointCompetitors",
      label: "Natjecatelji za koje se odnosi prethodni bodovi",

      isSelect: true,
      options: competitors,
    },
    {
      name: "olympicQualifyCompetitors",
      label: "2.3.1. kvalifikacija na OI",

      isSelect: true,
      options: competitors,
    },
    {
      name: "youthOlympicQualifyCompetitors",
      label: "2.3.2. kvalifikacija na OI mladih",

      isSelect: true,
      options: competitors,
    },
    {
      name: "aRaceRankingCompetitors",
      label: `2.3.3. plasman na svjetskim, europskim i “A” utrkama, prvenstvima u OLIMPIJSKIM
      disciplinama, bez kvalifikacija za nastup, po kriteriju Pravilnika za
      kategorizaciju sportaša HOO-a`,

      isSelect: true,
      options: competitors,
    },
    {
      name: "aRaceRankingCompetitorsDescription",
      label: "Kratak opis za prethodne natjecatelje",
    },
    {
      name: "etuRaceRankingCompetitors",
      label: `2.3.4. plasman na europskim prvenstvima u neolimpijskim
      disciplinama ETU, bez kvalifikacija za nastup po kriteriju Pravilnika za
      kategorizaciju sportaša HOO-a`,

      isSelect: true,
      options: competitors,
    },
    {
      name: "etuRaceRankingCompetitorsDescription",
      label: "Kratak opis za prethodne natjecatelje",
    },
    {
      name: "licensedTrainers",
      label: `5.4. Licencirani trener, svaki (max 1 trener na 15 sportaša)`,

      isSelect: true,
      options: fakeCompetitors,
      isCreatable: true,
    },
    {
      name: "licensedReferee",
      label: "5.5. Licencirani sudac, svaki (max 60 bodova)",

      isSelect: true,
      options: fakeCompetitors,
      isCreatable: true,
    },
    {
      name: "coachOnNationalCamp",
      label: "5.6. Trener na pripremama reprezentacije",

      isSelect: true,
      options: fakeCompetitors,
      isCreatable: true,
    },
    {
      name: "categorizationCompetitor",
      label: "KATEGORIZIRANI SPORTAŠI",
      isSelect: true,
      options: competitors,
    },
    {
      name: "categorizationWeight",
      label: "ZA SVAKOG SPORTAŠA REDOM VISINA KATEGORIJE",
      isSelect: true,
      options: [1, 2, 3, 4, 5, 6]
        .map((v) => ({
          label: `${v}. kategorija`,
          value: v,
          isSelected: false,
        }))
        .reduce((acc, cur) => {
          for (let i = 0; i < 20; i++) {
            acc.push({ ...cur, value: cur.value + "." + i });
          }
          return acc;
        }, []),
    },
  ];
  return (
    <div
      style={{ marginBottom: 150 }}
      key={initialFormProps ? initialFormProps.year : 0}
    >
      <h2>Podaci za vrednovanje klubova</h2>
      <a href="http://triatlon.hr/wp-content/uploads/2020/05/Pravilnik-o-vrednovanju-klubova-HTS-a-2019.-za-2020.-i-dalje.pdf">
        <DocumentIcon />
        Pravilnik o vrednovanju klubova
      </a>
      <br />
      <br />
      <Form onSubmit={async (formProps) => await onSubmit(formProps)}>
        {({ formProps = initialFormProps }) => (
          <form {...formProps}>
            {fields.map((field, index) =>
              !field.isSelect ? (
                <Field
                  key={index}
                  name={field.name}
                  defaultValue={initialFormProps[field.name]}
                  isRequired={field.isRequired}
                  label={field.label}
                >
                  {({ fieldProps, error }) => (
                    <>
                      <TextField
                        onChange={field.onChange ? field.onChange : () => {}}
                        autoComplete="off"
                        {...fieldProps}
                      />
                    </>
                  )}
                </Field>
              ) : (
                <Field
                  label={field.label}
                  name={field.name}
                  defaultValue={
                    initialFormProps[field.name]
                      ? initialFormProps[field.name].map((v, i) => {
                          if (field.name == "categorizationWeight") {
                            return field.options.find(
                              (f) => f.value == v + "." + i
                            );
                          }
                          if (field.name == "categorizationWeight") {
                            return;
                          }
                          return (
                            field.options.find((f) => f.value == v) || {
                              label: v,
                              value: v,
                            }
                          );
                        })
                      : field.name == "year"
                      ? {
                          label: initialFormProps.yearValue,
                          value: initialFormProps.yearValue,
                        }
                      : null
                  }
                >
                  {({ fieldProps, error }) => (
                    <>
                      {field.isCreatable ? (
                        <CreatableSelect
                          {...fieldProps}
                          isClearable
                          isMulti
                          options={field.options}
                        />
                      ) : (
                        <Select
                          {...fieldProps}
                          isMulti={field.isMulti === false ? false : true}
                          options={field.options}
                          placeholder={field.name}
                        />
                      )}
                    </>
                  )}
                </Field>
              )
            )}
            <FormFooter>
              <ButtonGroup>
                <Button
                  type="submit"
                  appearance="primary"
                  isLoading={submitting}
                >
                  Spremi podatke
                </Button>
              </ButtonGroup>
            </FormFooter>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ClubData;
