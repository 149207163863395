import React from 'react';
import Avatar from '@atlaskit/avatar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
`;

const Info = styled.div`
  display: grid;
  margin-left: 8px;
  align-content: center;
`;

const First = styled(Link)`
  font-size: 12px;
`;

const Second = styled(Link)`
  font-size: 10px;
`;

function SearchResult(props) {
  const { first, second } = props;
  return (
    <Container>
      <Avatar size='large' />
      <Info>
        <First to={first.to}>
          {first.name || ''} {first.surname || ''}
        </First>
        {second.name && <Second to={second.to}>{second.name || ''}</Second>}
      </Info>
    </Container>
  );
}

export default SearchResult;
