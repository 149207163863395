import React, { useState } from 'react';
import { useStateValue } from '../../contexts/AppContext';
import { deleteData } from '../../actions';
import Modal from '@atlaskit/modal-dialog';
import TextField from '@atlaskit/textfield';

const DeleteModal = ({
  onClose,
  infoText,
  value,
  deleteRoute,
  id,
  redirect
}) => {
  const [appState, dispatch] = useStateValue();
  const [deletingDisabled, setDeletingDisabled] = useState(true);
  const actions = [
    {
      text: 'Obriši',
      onClick: async () => {
        await deleteData(deleteRoute, id, dispatch, redirect);
      },
      isDisabled: deletingDisabled,
      appearance: 'danger'
    },
    { text: 'Odustani', onClick: onClose }
  ];
  return (
    <Modal
      key='active-modal'
      actions={actions}
      appearance='danger'
      onClose={onClose}
      heading={`Brisanje`}
    >
      <p>{infoText}</p>
      <TextField
        autoComplete='off'
        type='text'
        onChange={event => {
          if (event.target.value == value) {
            setDeletingDisabled(false);
          } else {
            setDeletingDisabled(true);
          }
        }}
      />
    </Modal>
  );
};

export default DeleteModal;
