import React from 'react';
import { Button } from '@atlaskit/button/components/Button';

export default function Chip({ label, onChange, selected }) {
  return (
    <Button appearance={selected ? 'primary' : 'default'} onClick={onChange}>
      {label}
    </Button>
  );
}
