import React from 'react';
import styled from 'styled-components';

const imageSize = {
  small: '50px',
  medium: '100px',
  large: '200px'
};

const BaseImage = styled.img`
  width: ${({ size }) => imageSize[size]};
`;

const Logo = props => {
  return (
    <BaseImage
      
      src={require('../assets/images/logo.png')}
      {...props}
    />
  );
};

export default Logo;
