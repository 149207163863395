import React, { useState, useEffect } from "react";
import PageHeader from "@atlaskit/page-header";
import Page from "@atlaskit/page";
import Spinner from "@atlaskit/spinner";
import { getData, updateData, deleteData, getApiData } from "../../actions";
import { Link } from "react-router-dom";
import CompetitorForm from "../../components/Forms/CompetitorForm";
import { useStateValue } from "../../contexts/AppContext";
import CompetitorMeasures from "./CompetitorMeasures";
import { DeleteModal } from "../../components/Modals";
import { Button } from "@atlaskit/button/components/Button";

const helperFunctions = async (
  id,
  setCompetitorData,
  setIsLoading,
  dispatch
) => {
  const response = await getData("/api/competitor", id);
  await getApiData("availableClubs", "/api/all_clubs_available", dispatch);
  setCompetitorData(response.data);
  setIsLoading(false);
};

const CompetitorSettings = props => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [competitorData, setCompetitorData] = useState({});
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    helperFunctions(id, setCompetitorData, setIsLoading, dispatch);
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async formProps => {
    setSubmitting(true);
    await updateData(
      "/api/competitor",
      { ...formProps, id },
      dispatch,
      "/competitors"
    );
    setSubmitting(false);
  };

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <Page>
        <PageHeader>
          <Link to={`/competitors/${id}`}>
            {competitorData.name} {competitorData.surname}
          </Link>
        </PageHeader>
        <h3>Postavke</h3>
        <CompetitorForm
          onSubmit={onSubmit}
          submitting={submitting}
          initialFormProps={competitorData}
          submitButtonText="Promijeni"
          clubOptions={
            state.apiData["availableClubs"]
              ? state.apiData["availableClubs"].length
                ? state.apiData["availableClubs"]
                : [state.apiData["availableClubs"]]
              : []
          }
        />
        <CompetitorMeasures id={id} />
        <Button onClick={() => setIsOpen(true)}>Delete</Button>
        {isOpen && (
          <DeleteModal
            onClose={() => setIsOpen(false)}
            infoText="Upišite ime natjecatelja kojeg ćete obrisati"
            value={competitorData.name}
            deleteRoute="/api/competitor"
            id={id}
            redirect="/competitors"
          />
        )}
      </Page>
    );
  }
};

export default CompetitorSettings;
