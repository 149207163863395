import React, { useState, useEffect } from "react";
import PageHeader from "@atlaskit/page-header";
import Page from "@atlaskit/page";
import Spinner from "@atlaskit/spinner";
import { getData, getApiData } from "../../actions";
import { Link } from "react-router-dom";
import { useStateValue } from "../../contexts/AppContext";
import CompetitorMeasures from "./CompetitorMeasures";

const helperFunctions = async (
  id,
  setCompetitorData,
  setIsLoading,
  dispatch
) => {
  const response = await getData("/api/competitor", id);
  await getApiData("availableClubs", "/api/all_clubs_available", dispatch);
  setCompetitorData(response.data);
  setIsLoading(false);
};

const CompetitorSettings = props => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [competitorData, setCompetitorData] = useState({});
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    helperFunctions(id, setCompetitorData, setIsLoading, dispatch);
  }, []);


  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <Page>
        <PageHeader>
          <Link to={`/competitors/${id}`}>
            {competitorData.name} {competitorData.surname}
          </Link>
        </PageHeader>
        <CompetitorMeasures id={id} />
      </Page>
    );
  }
};

export default CompetitorSettings;
