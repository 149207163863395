import React, { useState } from "react";
import RecentIcon from "@atlaskit/icon/glyph/recent";
import EmojiCustomIcon from "@atlaskit/icon/glyph/emoji/custom";
import EditorDividerIcon from "@atlaskit/icon/glyph/editor/divider";
import CheckCircleIcon from "@atlaskit/icon/glyph/check-circle";
import Button from "@atlaskit/button";
import axios from "axios";

const LicenseStatus = ({
  status = { value: 0 },
  id,
  token,
  setLicense,
  clubId,
  clubs
}) => {
  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) {
    return <Button isLoading={isLoading} />;
  }
  switch (status.value) {
    case 0:
      return token === "1" ||
        (token === "2" && parseInt(clubId, 10) === (clubs && clubs[0])) ? (
        <Button
          onClick={async () => {
            setIsLoading(true);
            const result = await axios.put("/api/license", {
              competitorId: id,
              clubId: status.clubId
            });
            if (result.status === 200) {
              setLicense(1);
            }
            setIsLoading(false);
          }}
        >
          <EmojiCustomIcon />
        </Button>
      ) : (
        <EditorDividerIcon
          size="large"
          style={{ marginLeft: "6px" }}
          primaryColor="red"
        />
      );
    case 1:
      return (
        <RecentIcon
          size="large"
          style={{ marginLeft: "6px" }}
          primaryColor="orange"
        />
      );
    case 2:
      return (
        <CheckCircleIcon
          size="large"
          style={{ marginLeft: "6px" }}
          primaryColor="green"
        />
      );
    default:
      return null;
  }
};

export default LicenseStatus;
