import React, { useEffect } from 'react';
import PageHeader from '@atlaskit/page-header';
import Page, { Grid, GridColumn } from '@atlaskit/page';
import Button from '@atlaskit/button';
import { useStateValue } from "../../contexts/AppContext";
import ReactTable from "react-table";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import axios from "axios";
import { getApiData } from "../../actions";
import Wrapper from "../../components/Wrapper";

const Moderators = () => {
  const [state, dispatch] = useStateValue();
  const tableName = "moderators";

  useEffect(() => {
    getApiData(tableName, "/api/moderator", dispatch);
  }, []);

  return (
    <Page>
      <PageHeader>Moderatori</PageHeader>
      <Grid spacing='cosy'>
        <GridColumn medium={10} />
        <GridColumn medium={2}>
          <Button appearance='primary' href='/moderators/new'>
            Novi moderator
          </Button>
        </GridColumn>
      </Grid>
      <Grid spacing='cosy'>
        <GridColumn medium={12}>
        {state.apiData[tableName] && 
          <Wrapper>
            <ReactTable
              showPagination={state.apiData[tableName].length > 20}
              data={(state.apiData[tableName])}
              columns={[
                {
                  Header: "Id",
                  accessor: "id",
                  maxWidth: 50
                },
                {
                  Header: "Korisničko ime",
                  accessor: "username",
                  maxWidth: 800,
                },
                {
                  Header: "Izbriši",
                  accessor: "delete",
                  maxWidth: 100,
                  Cell: row => (
                    <Button
                      onClick={async () => {
                        await axios.delete("/api/moderator", { params: { id: row.original.id } });
                        getApiData(tableName, "/api/moderator", dispatch);
                      }}
                    >
                  <CrossCircleIcon />
                </Button>
                  )
                }
              ]}
              defaultSorted={[
                {
                  id: "id",
                  desc: false
                }
              ]}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          </Wrapper>
        }
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default Moderators;
