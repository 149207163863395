import React from 'react';
import Error from '@atlaskit/icon/glyph/error';
import Info from '@atlaskit/icon/glyph/info';
import Tick from '@atlaskit/icon/glyph/check-circle';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { useStateValue } from '../../contexts/AppContext';

const iconMap = key => {
  const icons = {
    info: <Info label='Info icon' secondaryColor={colors.N500} />,
    success: <Tick label='Success' secondaryColor={colors.G400} />,
    warning: <Warning label='Warning icon' secondaryColor={colors.Y300} />,
    error: <Error label='Error icon' secondaryColor={colors.R300} />
  };

  return key ? icons[key] : icons;
};

const BaseFlag = props => {
  const [appState, dispatch] = useStateValue();

  return (
    <>
      {Object.keys(appState.errors).map(key => {
        return (
          <FlagGroup onDismissed={() => dispatch({ type: 'clearError' })}>
            <AutoDismissFlag
              actions={[]}
              appearance={key}
              description={appState.errors[key].description}
              icon={iconMap(key)}
              id={key}
              isDismissAllowed
              key={key}
              title={appState.errors[key].title}
            />
          </FlagGroup>
        );
      })}
    </>
  );
};

export default BaseFlag;
