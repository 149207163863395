import React, { useEffect } from "react";
import { getApiData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import moment from "moment";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import DocumentsIcon from "@atlaskit/icon/glyph/documents";
import { useCookies } from "react-cookie";

const MAX_ROWS_PER_PAGE = 50;

const Races = ({ first, last }) => {
  const [state, dispatch] = useStateValue();
  const [cookies] = useCookies(["token"]);
  const apiTableName = first
    ? "raceEventsFirst"
    : last
    ? "raceEventsLast"
    : "raceEvents";

  useEffect(() => {
    getApiData(
      apiTableName,
      "/api/race" +
        (first ? "?first=" + first : last ? "?last=" + last : "") +
        (first || last ? "&categoryId=1" : ""),
      dispatch
    );
  }, []);

  return (
    <>
      {state.apiData[apiTableName] && (
        <ReactTable
          showPagination={
            state.apiData[apiTableName].length > MAX_ROWS_PER_PAGE
          }
          data={state.apiData[apiTableName]}
          columns={[
            {
              Header: "Datum",
              accessor: "date",
              maxWidth: 100,
              Cell: (row) => (
                <span>{moment(row.value).format("DD.MM.YYYY")}</span>
              ),
            },
            {
              Header: "Utrka",
              accessor: "name",
              maxWidth: 450,
              Cell: (row) => (
                <Link to={`/race-event/${row.original.id}`}>{row.value}</Link>
              ),
            },
            {
              Header: "Disciplina",
              accessor: "raceTypeName",
              maxWidth: 200,
              Cell: (row) => <span>{row.value}</span>,
            },
            {
              Header: "Rang utrke",
              accessor: "rankName",
              maxWidth: 150,
            },
          ]}
          defaultSorted={[
            {
              id: "date",
              asc: true,
            },
          ]}
          defaultPageSize={MAX_ROWS_PER_PAGE}
          pageSize={Math.max(
            state.apiData[apiTableName].length < MAX_ROWS_PER_PAGE
              ? state.apiData[apiTableName].length
              : MAX_ROWS_PER_PAGE,
            5
          )}
          className="-striped -highlight"
        />
      )}
    </>
  );
};

export default Races;
