const formatOptions = data => {
  if (!data) return [];
  return data.map(value => {
    return {
      label: value.name + (value.sex ? ' ' + value.sex : ''),
      value: value.id
    };
  }).sort((a, b) => a.value > b.value);
};

export default formatOptions;
