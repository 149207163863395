import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useStateValue } from "../../contexts/AppContext";
import { getApiData } from "../../actions";
import ReactTable from "react-table";
import StartListStatus from "../StartListStatus";
import { useCookies } from "react-cookie";

const getCatVal = (cat) => {
  if (cat.includes("KAD")) {
    return 1;
  } else if (cat.includes("JUN")) {
    return 2;
  } else if (cat.includes("SEN")) {
    return 3;
  }
  return 0;
};

const replaceGroupCatValues = (cat) => {
  return cat
    .replace("KADETKINJE", "KADETI")
    .replace("SENIORKE", "SENIORI")
    .replace("JUNIORKE", "JUNIORI");
};

const helperFunctions = async (id, tableName, dispatch) => {
  await Promise.all([
    getApiData(tableName, `/api/startList?raceId=${id}`, dispatch),
  ]);
};

const StartListTable = ({
  id,
  isGroupRace,
  isFinalRace,
  isSeniorRace,
  isMaleSeniorRace,
}) => {
  const tableName = `raceStartList-${id}`;
  const [state, dispatch] = useStateValue();
  const [cookies] = useCookies(["token"]);
  const [startListData, setStartListData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    helperFunctions(id, tableName, dispatch);
  }, []);

  useEffect(() => {
    if (!state.apiData[tableName]) {
      setStartListData([]);
      return;
    }
    if (isGroupRace) {
      const tmpStartListData = {};
      state.apiData[tableName].forEach((competitor) => {
        if (!tmpStartListData[competitor.groupId]) {
          tmpStartListData[competitor.groupId] = {
            ...competitor,
            bib: competitor.groupId,
            competitors: [],
          };
        }
        tmpStartListData[competitor.groupId].competitors.push(competitor);
        if (
          getCatVal(competitor.categories) >
          getCatVal(tmpStartListData[competitor.groupId].categories)
        ) {
          tmpStartListData[competitor.groupId].dateOfBirth =
            competitor.dateOfBirth;
          tmpStartListData[competitor.groupId].categories =
            competitor.categories;
        }
        Object.keys(tmpStartListData).forEach((key) => {
          tmpStartListData[key].categories = replaceGroupCatValues(
            tmpStartListData[key].categories
          );
        });
        setStartListData(Object.values(tmpStartListData));
      });
    } else {
      setStartListData(state.apiData[tableName]);
    }
  }, [state.apiData[tableName], isGroupRace]);

  const mainStartListData =
    startListData && (isSeniorRace && isFinalRace)
      ? startListData
          .filter((d) => d.priority === false)
          .filter(
            (d) =>
              !d.categories.includes("JUNIOR") &&
              ((!showAll &&
                (d.qualifyPoints !== -1 && d.qualifyPoints !== undefined)) ||
                showAll)
          )
      : isFinalRace
      ? startListData.filter(
          (d) => d.qualifyPoints !== -1 && d.qualifyPoints !== undefined
        )
      : startListData;
  const secondaryStartListData =
    startListData && (isSeniorRace && isFinalRace)
      ? startListData
          .filter((d) => d.priority === false)
          .filter(
            (d) =>
              d.categories.includes("JUNIOR") &&
              ((!showAll &&
                (d.qualifyPoints !== -1 && d.qualifyPoints !== undefined)) ||
                showAll)
          )
      : null;

  const priorityStartList =
    startListData && isFinalRace
      ? startListData.filter((d) => d.priority === true)
      : null;

  let maxSecondaryCompetitors = isMaleSeniorRace ? 30 : 30;
  let maxMainCompetitors = isSeniorRace
    ? (isMaleSeniorRace ? 30 : 30) +
      (secondaryStartListData &&
      maxSecondaryCompetitors > secondaryStartListData.length
        ? maxSecondaryCompetitors - secondaryStartListData.length
        : 0)
    : 1000;

  for (let i = maxMainCompetitors; i < mainStartListData.length; i += 1) {
    if (
      mainStartListData[i].qualifyPoints ===
      mainStartListData[i - 1].qualifyPoints
    ) {
      maxMainCompetitors += 1;
    } else {
      break;
    }
  }

  if (secondaryStartListData) {
    for (
      let i = maxSecondaryCompetitors;
      i < secondaryStartListData.length;
      i += 1
    ) {
      if (
        secondaryStartListData[i].qualifyPoints ===
        secondaryStartListData[i - 1].qualifyPoints
      ) {
        maxSecondaryCompetitors += 1;
      } else {
        break;
      }
    }
  }

  return (
    <>
      {isFinalRace && (
        <div>
          <h2>Lista natjecatelja s pravom prijave</h2>
          <h5>
            <span style={{ color: "green" }}>Zelenom bojom</span> su označeni
            natjecatelji s pravom prijave
            <br />
            <span style={{ color: "red" }}>Crvenom bojom</span> su označeni
            natjecatelji na listi čekanja
          </h5>
        </div>
      )}
      {[priorityStartList, mainStartListData, secondaryStartListData].map(
        (sld, index) =>
          sld &&
          sld.length > 0 && (
            <div style={{ paddingBottom: 40 }}>
              {isFinalRace && index === 0 && (
                <>
                  <h3>Poziv izbornika ili ITU/ETU bodovi</h3>
                  <p style={{ fontSize: 10 }}>
                    <b>POUN 2.16.</b> Pravo nastupa na završnom natjecanju HTL-a
                    koje je ujedno i državno prvenstvo ostvaruju svi
                    natjecatelji uvršteni na World Triathlon Ranking ili ETU
                    Ranking ljestvici temeljem bodova ostvarenim na natjecanjima
                    razine europskog kupa, svjetskog kupa ili World Triathlon
                    (juniori i seniori).
                    <br />
                    <b>POUN 2.17.</b> 1 Izbornik ima diskrecijsko pravo
                    prijaviti po 2 dodatna natjecatelja u muškoj i ženskoj
                    konkurenciji po svakoj disciplini završnog natjecanja HTL-e,
                    koje je ujedno i državno prvenstvo.
                  </p>
                </>
              )}
              {isSeniorRace && isFinalRace && index > 0 && (
                <h2>{["", "SENIORI", "JUNIORI"][index]}</h2>
              )}
              <ReactTable
                key={sld}
                showPagination={false}
                data={sld}
                columns={[
                  {
                    Header: "Pozicija",
                    accessor: "bib",
                    maxWidth: 50,
                    Cell: (row) => {
                      return <span>{row.index + 1}</span>;
                    },
                  },
                  {
                    Header: "Kvalifikacijski poredak",
                    accessor: "bib",
                    maxWidth: 100,
                  },
                  {
                    Header: "Kvalifikacijski bodovi",
                    accessor: "qualifyPoints",
                    show: isFinalRace,
                    maxWidth: 150,
                    Cell: (row) => {
                      return (
                        <div
                          style={{
                            background:
                              row.index <
                              [2, maxMainCompetitors, maxSecondaryCompetitors][
                                index
                              ]
                                ? "green"
                                : "red",
                            color: "#FFF",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {row.value}
                        </div>
                      );
                    },
                  },
                  {
                    Header: "Licenca",
                    accessor: "licenseId",
                    show: !isGroupRace,
                    maxWidth: 100,
                  },
                  {
                    ...(isGroupRace
                      ? {
                          Header: "Natjecatelji",
                          accessor: "competitorId",
                          maxWidth: 800,
                          Cell: (row) => (
                            <span>
                              {row.original.competitors &&
                                row.original.competitors.map((c, index) => (
                                  <span>
                                    <Link to={`/competitors/${c.competitorId}`}>
                                      {c.Competitor.name} {c.Competitor.surname}
                                    </Link>
                                    {row.original.competitors.length >
                                      index + 1 && ", "}
                                  </span>
                                ))}
                            </span>
                          ),
                        }
                      : {
                          Header: "Natjecatelj",
                          accessor: "competitorId",
                          maxWidth: 300,
                          Cell: (row) => (
                            <Link
                              to={`/competitors/${row.original.competitorId}`}
                            >
                              {row.original.Competitor.name}{" "}
                              {row.original.Competitor.surname}
                            </Link>
                          ),
                        }),
                  },
                  {
                    Header: "Klub",
                    accessor: "clubId",
                    maxWidth: 300,
                    Cell: (row) => (
                      <Link to={`/clubs/${row.original.Competitor.club}`}>
                        {row.original.Competitor.Club.name}
                      </Link>
                    ),
                  },
                  {
                    Header: "Godina rođenja",
                    accessor: "dateOfBirth",
                    maxWidth: 150,
                    show: cookies.token === "1" && !isGroupRace,
                    Cell: (row) => (
                      <span>
                        {moment(row.original.Competitor.dateOfBirth).format(
                          "YYYY."
                        )}
                      </span>
                    ),
                  },
                  {
                    Header: "Kategorije",
                    accessor: "categories",
                    maxWidth: 200,
                    Cell: (row) => (
                      <span>
                        {isGroupRace ? row.value.split(" ")[0] : row.value}
                      </span>
                    ),
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    maxWidth: 150,
                    Cell: (row) => {
                      const status = { value: row.original.status };
                      return (
                        <StartListStatus
                          status={status}
                          id={row.original.id}
                          competitorClubId={row.original.Competitor.club}
                        />
                      );
                    },
                  },
                ]}
                defaultSorted={[
                  {
                    id: "bib",
                    asc: true,
                  },
                ]}
                defaultPageSize={sld.length}
                className="-striped -highlight"
              />
            </div>
          )
      )}
      <a onClick={() => setShowAll(!showAll)}>
        {showAll ? "Show only qualified" : "Show all"}
      </a>
    </>
  );
};

export default StartListTable;
