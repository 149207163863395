import React, { useState, useEffect } from "react";
import { getData, getApiData } from "../../actions";
import Page from "../../components/Page";
import createCompetitorInfo from "./helpers/createCompetitorInfo";
import { useStateValue } from "../../contexts/AppContext";
import ReactTable from "react-table";
import moment from "moment";
import { Link } from "react-router-dom";

const MAX_ROWS_PER_PAGE = 50;

const helperFunctions = async (id, setCompetitorData, setIsLoading) => {
  const response = await getData("/api/competitor", id);
  setCompetitorData(response.data);
  setIsLoading(false);
};

const Competitor = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [competitorData, setCompetitorData] = useState({});
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    helperFunctions(id, setCompetitorData, setIsLoading);
    getApiData(`raceEvents-${id}`, "/api/race", dispatch, {
      competitorId: id,
    });
    getApiData(`next-races-${id}`, `/api/startListUser`, dispatch, { id });
  }, []);

  useEffect(() => {
    if (competitorData.globalId && competitorData.globalId > 0) {
      getApiData(
        `globalRaceEvents-${competitorData.globalId}`,
        "/api/globalRace",
        dispatch,
        {
          id: competitorData.globalId,
        }
      );
      getApiData(`next-global-races-${id}`, `/api/startListGlobal`, dispatch, {
        id: competitorData.globalId,
      });
      getApiData(
        `competitor-global-data-${id}`,
        `/api/competitor-global`,
        dispatch,
        {
          id: competitorData.globalId,
        }
      );
    }
  }, [competitorData]);

  const ituData = state.apiData[`competitor-global-data-${id}`];

  return (
    <Page
      title={
        `${competitorData.name} ${competitorData.surname}` || "Natjecatelj"
      }
      img={
        ituData && ituData.athlete_profile_image ? (
          <div style={{ flex: 1 }}>
            <img
              src={ituData.athlete_profile_image}
              width="150px"
              height="150px"
              style={{ objectFit: "cover" }}
            />
          </div>
        ) : null
      }
      otherInfo={
        ituData ? (
          <div style={{ flex: 1 }}>
            {ituData.current_rankings ? (
              <div>
                <h2>ITU Ranking</h2>
                {Object.values(ituData.current_rankings).map((ranking) => (
                  <div>
                    <b>{ranking.ranking_name}</b>
                    <span> {ranking.ranking}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null
      }
      toolBarActions={["confirmation", "settings", "headCoachSettings"]}
      info={createCompetitorInfo(competitorData)}
    >
      {state.apiData[`next-global-races-${id}`] &&
      state.apiData[`next-global-races-${id}`].length ? (
        <div>
          <h2>Sljedeće ITU utrke</h2>
          <ReactTable
            showPagination={false}
            data={state.apiData[`next-global-races-${id}`]}
            columns={[
              {
                Header: "Utrka",
                accessor: "event.event_title",
                maxWidth: 500,
                Cell: (row) => (
                  <a
                    href={`https://triathlon.org/events/${
                      row.original.event.event_slug
                    }`}
                    target="_blank"
                  >
                    {row.value}
                  </a>
                ),
              },
              {
                Header: "Datum",
                accessor: "prog_date",
                maxWidth: 200,
                Cell: (row) => (
                  <span>{moment(row.value).format("DD.MM.YYYY")}</span>
                ),
              },
            ]}
            defaultPageSize={state.apiData[`next-races-${id}`].length}
          />
        </div>
      ) : null}
      {state.apiData[`next-races-${id}`] &&
      state.apiData[`next-races-${id}`].length ? (
        <div>
          <h2>Sljedeće utrke</h2>
          <ReactTable
            showPagination={false}
            data={state.apiData[`next-races-${id}`]}
            columns={[
              {
                Header: "Utrka",
                accessor: "Race.name",
                maxWidth: 300,
                Cell: (row) => (
                  <Link to={`/races/${row.original.Race.id}`}>
                    {row.original.Race.name}
                  </Link>
                ),
              },
              {
                Header: "Datum",
                accessor: "Race.date",
                maxWidth: 200,
                Cell: (row) => (
                  <span>{moment(row.value).format("DD.MM.YYYY")}</span>
                ),
              },
            ]}
            defaultPageSize={state.apiData[`next-races-${id}`].length}
          />
        </div>
      ) : null}
      {competitorData && competitorData.Licenses && (
        <div>
          <h2>Klubovi</h2>
          <ReactTable
            showPagination={competitorData.Licenses.length > MAX_ROWS_PER_PAGE}
            data={competitorData.Licenses.filter((l) => l.approved)}
            columns={[
              {
                Header: "Klub",
                accessor: "club",
                maxWidth: 200,
                Cell: (row) => (
                  <Link to={`/clubs/${row.value.id}`}>{row.value.name}</Link>
                ),
              },
              {
                Header: "Početak",
                accessor: "startDate",
                maxWidth: 100,
                Cell: (row) => (
                  <span>{moment(row.value).format("DD.MM.YYYY")}</span>
                ),
              },
              {
                Header: "Kraj",
                accessor: "endDate",
                maxWidth: 100,
                Cell: (row) => (
                  <span>
                    {moment(
                      row.value ||
                        (competitorData.Licenses[row.index + 1]
                          ? competitorData.Licenses[row.index + 1].startDate ||
                            new Date(
                              new Date(row.original.startDate).getFullYear(),
                              11,
                              31
                            )
                          : new Date(
                              new Date(row.original.startDate).getFullYear(),
                              11,
                              31
                            ))
                    ).format("DD.MM.YYYY")}
                  </span>
                ),
              },
            ]}
            defaultSorted={[
              {
                id: "date",
                desc: true,
              },
            ]}
            defaultPageSize={5}
            pageSize={
              competitorData.Licenses.filter((l) => l.approved).length < 5
                ? competitorData.Licenses.filter((l) => l.approved).length || 5
                : 5
            }
            className="-striped -highlight"
          />
        </div>
      )}
      <h1>Rezultati</h1>
      {state.apiData[`raceEvents-${id}`] &&
        state.apiData[`raceEvents-${id}`]
          .reduce((acc, race) => {
            const year = new Date(race.date).getFullYear();
            if (!acc.includes(year)) {
              acc.push(year);
            }
            return acc;
          }, [])
          .sort((a, b) => b - a)
          .map((year) => {
            const yearData = state.apiData[`raceEvents-${id}`].filter(
              (r) => new Date(r.date).getFullYear() === year
            );
            return (
              <div>
                <h2>{year}</h2>
                <ReactTable
                  showPagination={
                    state.apiData[`raceEvents-${id}`].length > MAX_ROWS_PER_PAGE
                  }
                  data={yearData}
                  columns={[
                    {
                      Header: "Pozicija",
                      accessor: "position",
                      maxWidth: 50,
                    },
                    {
                      Header: "Utrka",
                      accessor: "raceName",
                      Cell: (row) => (
                        <Link to={`/races/${row.original.raceId}`}>
                          {row.value}
                        </Link>
                      ),
                    },

                    {
                      Header: "Datum",
                      accessor: "date",
                      maxWidth: 100,
                      Cell: (row) => (
                        <span>{moment(row.value).format("DD.MM.YYYY")}</span>
                      ),
                    },
                    {
                      Header: "Disciplina",
                      accessor: "raceTypeName",
                    },
                    {
                      Header: "Rang utrke",
                      accessor: "rankName",
                    },
                    {
                      Header: "Natjecateljska kat.",
                      accessor: "categoryName",
                      maxWidth: 100,
                      Cell: (row) => (
                        <span>
                          {row.value} {row.original.sex}
                        </span>
                      ),
                    },
                    {
                      Header: "Tip kupa",
                      accessor: "cupName",
                    },
                  ]}
                  defaultSorted={[
                    {
                      id: "date",
                      desc: true,
                    },
                  ]}
                  defaultPageSize={MAX_ROWS_PER_PAGE}
                  pageSize={
                    yearData.length < MAX_ROWS_PER_PAGE
                      ? yearData.length || 5
                      : MAX_ROWS_PER_PAGE
                  }
                  className="-striped -highlight"
                />
              </div>
            );
          })}
      {state.apiData[`globalRaceEvents-${competitorData.globalId}`] &&
        state.apiData[`globalRaceEvents-${competitorData.globalId}`].length && (
          <>
            <h2>ITU utrke</h2>
            <ReactTable
              showPagination={
                state.apiData[`globalRaceEvents-${competitorData.globalId}`]
                  .length > MAX_ROWS_PER_PAGE
              }
              data={
                state.apiData[`globalRaceEvents-${competitorData.globalId}`]
              }
              columns={[
                {
                  Header: "Pozicija",
                  accessor: "position",
                  maxWidth: 50,
                },
                {
                  Header: "Utrka",
                  accessor: "event_title",
                  Cell: (row) => (
                    <a href={row.original.result_listing}>{row.value}</a>
                  ),
                },

                {
                  Header: "Datum",
                  accessor: "prog_date",
                  maxWidth: 100,
                },
                {
                  Header: "Disciplina",
                  accessor: "event_specifications",
                  Cell: (row) => (
                    <span>
                      {row.value.map((value) => value.cat_name).join(" ")}
                    </span>
                  ),
                },
              ]}
              pageSize={
                state.apiData[`globalRaceEvents-${competitorData.globalId}`]
                  .length
              }
              className="-striped -highlight"
            />
          </>
        )}
    </Page>
  );
};

export default Competitor;
