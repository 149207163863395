import React, { useState, useEffect } from "react";
import { getData, createRegistration } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import { CheckboxSelect } from "@atlaskit/select";
import Button from "@atlaskit/button";
import Page from "../../components/Page";
import createRaceInfo from "./helpers/createRaceInfo";
import { GridColumn, Grid } from "@atlaskit/page";
import { Checkbox } from "@atlaskit/checkbox";
import { useCookies } from "react-cookie";

const helperFunctions = async (id, clubId, setRaceData, setIsLoading, setOptions, setClubData) => {
  const response = await getData("/api/race", id);
  setRaceData(response.data);
  const competitors = (await getData(`/api/raceCompetitors`, id)).data;
  setOptions(
    competitors.map((competitor) => {
      return {
        label: competitor.name + " " + competitor.surname,
        value: competitor.id,
      };
    })
  );
  if (clubId) {
    const club = (await getData("/api/club", clubId)).data;
    setClubData(club);
  }
  setIsLoading(false);
};

const submit = async (
  competitors,
  dispatch,
  raceId,
  isGroupRace,
  priority = false
) => {
  if (isGroupRace && competitors.length !== 4) {
    return false;
  }
  await createRegistration(
    {
      competitorIds: competitors.map((competitor) => competitor.value),
      raceId,
      priority,
    },
    dispatch
  );
  return true;
};

const RaceRegister = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [raceData, setRaceData] = useState({});
  const [state, dispatch] = useStateValue();
  const [selectedCompetitors, setSelectedCompetitors] = useState([]);
  const [options, setOptions] = useState([]);
  const [club, setClubData] = useState(null);
  const [isPriority, setIsPriority] = useState(false);
  const [cookies] = useCookies(["token"]);
  const clubId = cookies && cookies.clubs && cookies.clubs[0];

  useEffect(() => {
    helperFunctions(id, clubId, setRaceData, setIsLoading, setOptions, setClubData);
  }, []);

  useEffect(() => {
    if (raceData.allowRegistration === false && cookies.token !== "1") {
      dispatch({
        type: "redirect",
        payload: `/races/${id}`,
      });
    }
  }, [raceData]);

  const [submitting, setSubmitting] = useState(false);

  const isGroupRace = raceData.raceTypeId === 14;
  const isFinalRace = raceData.rankId === 2 && raceData.cupId === 4;
  const isSeniorRace = [1, 2].includes(raceData.categoryId);

  return (
    <Page title={raceData.name} info={createRaceInfo(raceData)}>
      <h3>Prijava na utrku</h3>
      {isGroupRace && (
        <h5 style={{ color: "red" }}>Odaberite točno 4 natjecatelja</h5>
      )}
      {isSeniorRace && isFinalRace && (
        <h5>
          <b>POUN HTS-a 2.15 </b>
          2.15. Pravo nastupa na završnom natjecanju HTL-a koje je ujedno i
          državo prvenstvo, ostvaruje najboljih 60 natjecatelja i 40
          natjecateljica po pojedinoj disciplini, a prema redoslijedu u koje
          ulaze 2 (dva) najbolja plasmana iz kvalifikacijskih utrka, uz
          minimalno 2 nastupa. Od ukupnog broja od 60 natjecatelja i 40
          natjecateljica ulazi minimalno 30 juniora i 25 juniorki, bez obzira na
          ukupni plasman na ljestvici HTL-a, a nadalje prema stanju na bodovnoj
          ljestvici.
        </h5>
      )}
      {raceData.registerUrl ? (
        <iframe src={raceData.registerUrl + "?iframe=1" + (club ? `&club=${club.name}` : '')} width={'100%'} height={'200%'} />
      ) : (
        <div>
          <Grid>
            <GridColumn medium={12}>
              {!isLoading && (
                <CheckboxSelect
                  onChange={(value) => setSelectedCompetitors(value)}
                  className="multi-select"
                  classNamePrefix="react-select"
                  options={options}
                  isMulti
                  isSearchable={true}
                  placeholder="Izaberite natjecatelje"
                />
              )}
            </GridColumn>
          </Grid>
          <br />
          <Grid>
            <GridColumn medium={12}>
              <Button
                isLoading={submitting}
                appearance="primary"
                onClick={async () => {
                  setSubmitting(true);
                  await submit(
                    selectedCompetitors,
                    dispatch,
                    id,
                    isGroupRace,
                    isPriority
                  );
                  setSubmitting(false);
                }}
              >
                Prijavi
              </Button>
            </GridColumn>
            {cookies.token === "1" && (
              <Checkbox
                isChecked={isPriority}
                label={"Priority"}
                onChange={(e) => {
                  setIsPriority(!isPriority);
                }}
              />
            )}
          </Grid>
        </div>
      )}
    </Page>
  );
};

export default RaceRegister;
