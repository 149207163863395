import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import { AppProvider } from './contexts/AppContext';
import AppReducer from './reducers/AppReducer';
import Navigation from './components/Navigation.jsx';
import { createGlobalStyle } from 'styled-components';
import { CookiesProvider } from 'react-cookie';

import './styles/index.css';

import Routes from './Routes';

localStorage.setItem('ATLASKIT_NAVIGATION_UI_STATE', JSON.stringify({
  isCollapsed: true,
  productNavWidth: 256
}));

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto !important;
  }
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a:active {
    text-decoration: underline;
  }
`;

const hist = createBrowserHistory();
const initialState = {
  history: hist,
  user: {},
  apiData: [],
  errors: {}
};

const reducer = AppReducer;

ReactDOM.render(
  <CookiesProvider>
    <AppProvider initialState={initialState} reducer={reducer}>
      <GlobalStyle />
      <Router history={hist}>
        <Navigation>
          <Routes />
        </Navigation>
      </Router>
    </AppProvider>
  </CookiesProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
