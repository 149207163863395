import React, { useState, useEffect } from 'react';
import PageHeader from '@atlaskit/page-header';
import Page from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';
import { getData, updateData, deleteData } from '../../actions';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../contexts/AppContext';
import Button from '@atlaskit/button';
import Modal from '@atlaskit/modal-dialog';
import TextField from '@atlaskit/textfield';
import RankForm from '../../components/Forms/RankForm';

const helperFunctions = async (id, setRankData, setIsLoading) => {
  const response = await getData('/api/rank', id);
  setRankData(response.data);
  setIsLoading(false);
};

const RankSettings = props => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [deletingDisabled, setDeletingDisabled] = useState(true);
  const [rankData, setRankData] = useState({});
  const [state, dispatch] = useStateValue();

  useEffect(() => {
    helperFunctions(id, setRankData, setIsLoading);
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async formProps => {
    setSubmitting(true);
    await updateData('/api/rank', { ...formProps, id }, dispatch, '/ranks');
    setSubmitting(false);
  };

  const actions = [
    {
      text: 'Obriši',
      onClick: async () => {
        await deleteData('/api/rank', id, dispatch, '/ranks');
      },
      isDisabled: deletingDisabled,
      appearance: 'danger'
    },
    { text: 'Odustani', onClick: () => setIsOpen(false) }
  ];

  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <Page>
        <PageHeader>
          <Link to={`/ranks/${id}`}>{rankData.name}</Link>
        </PageHeader>
        <h3>Postavke</h3>
        <RankForm
          onSubmit={onSubmit}
          submitting={submitting}
          initialFormProps={rankData}
          submitButtonText='Promijeni'
        />
        <Button appearance='danger' onClick={() => setIsOpen(true)}>
          Brisanje
        </Button>
        {isOpen && (
          <Modal
            key='active-modal'
            actions={actions}
            appearance='danger'
            onClose={() => setIsOpen(false)}
            heading={`Brisanje`}
          >
            <p>Upišite ime utrke koju ćete obrisati</p>
            <TextField
              autoComplete='off'
              type='text'
              onChange={event => {
                if (
                  rankData.name &&
                  rankData.name.length > 0 &&
                  event.target.value === rankData.name
                ) {
                  setDeletingDisabled(false);
                } else {
                  setDeletingDisabled(true);
                }
              }}
            />
          </Modal>
        )}
      </Page>
    );
  }
};

export default RankSettings;
