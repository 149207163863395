import React, { useState, useEffect } from "react";
import { getData } from "../../actions";
import ResultTable from "../../components/Tables/ResultTable";
import Page from "../../components/Page";
import StartListTable from "../../components/Tables/StartListTable";
import createRaceInfo from "./helpers/createRaceInfo";
import axios from "axios";
import LiveRace from "../../components/LiveRace";

const helperFunctions = async (id, setRaceData, setIsLoading) => {
  const response = await getData("/api/race", id);
  setRaceData(response.data);
  setIsLoading(false);
};

const Race = (props) => {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [raceData, setRaceData] = useState({});
  const [actions, setActions] = useState([]);
  useEffect(() => {
    helperFunctions(id, setRaceData, setIsLoading);
  }, []);
  const isGroupRace = raceData.raceTypeId === 14;
  const isFinalRace =
    raceData.rankId === 2 && raceData.cupId === 4 && raceData.categoryId < 7;
  const isSeniorRace = [1, 2].includes(raceData.categoryId);
  const isMaleSeniorRace = raceData.categoryId === 1;

  useEffect(() => {
    let tmpActions = [];
    if (!props.startList && raceData.results && raceData.results.length > 0) {
      tmpActions.push({
        label: "Startna lista",
        href: `/races/${id}/startlist`,
      });
    } else if (raceData.allowRegistration) {
      tmpActions.push({
        label: "Prijava na utrku",
        href: `/races/${id}/register`,
        clubAdmin: true,
      });
    }
    if (props.startList) {
      tmpActions.push({
        label: raceData.allowRegistration
          ? "Zabrani prijavu na utrke"
          : "Dopusti prijavu na utrke",
        onClick: async () => {
          await axios.post("/api/toggleRace", { id });
          window.location.reload();
        },
        appearance: raceData.allowRegistration ? "danger" : "primary",
        admin: true,
      });
    }
    tmpActions.push({
      label:
        raceData.results && raceData.results.length > 0
          ? "Uredi rezultate"
          : "Postavi rezultate",
      href: `/races/${id}/edit`,
      admin: true,
    });
    setActions(tmpActions);
  }, [raceData.results, raceData.allowRegistration]);

  return (
    <Page
      title={raceData.name}
      toolBarActions={[
        "settings",
        !props.startList && raceData.results && raceData.results.length > 0
          ? {
              type: "data",
              url: `/api/rezultati.xls?raceId=${id}`,
            }
          : {
              type: "data",
              url: `/api/PrijavljeniNatjecatelji.xls?raceId=${id}`,
            },
      ]}
      actions={actions}
      info={createRaceInfo(raceData)}
    >
      <br />
      {(props.startList || raceData) &&
        (!props.startList && raceData.results && raceData.results.length > 0 ? (
          <ResultTable
            editable={false}
            tableName={`race-${id}`}
            apiRoute={`/api/result?raceId=${id}`}
            raceData={raceData}
          />
        ) : raceData.startListUrl ? (
          <iframe src={raceData.startListUrl} width={'100%'} height={'200%'} />
        ) : (
          <StartListTable
            id={id}
            isGroupRace={isGroupRace}
            isFinalRace={isFinalRace}
            isSeniorRace={isSeniorRace}
            isMaleSeniorRace={isMaleSeniorRace}
          />
        ))}
    </Page>
  );
};

export default Race;
