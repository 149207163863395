import React, { useState } from 'react';
import Chip from './Chip';

export default function ChipList(props) {
  const { options, defaultValue, onChange, onValuesChange, multiple } = props;
  const [value, setValue] = useState(defaultValue);
  const [values, setValues] = useState([defaultValue].filter((v) => !!v));

  return (
    <div>
      {options.map(option => (
        option && <>
        {
          option.breakLine && <br />
        }
        <Chip
          key={option.label}
          label={option.label}
          value={option.value}
          selected={multiple ? values.includes(option.value) : (props.value === undefined ? value : props.value) === option.value}
          onChange={() => {
            if (multiple) {
              if (values.includes(option.value)) {
                values.splice(values.indexOf(option.value), 1);
              } else {
                values.push(option.value);
              }
              setValues([...values]);
              onValuesChange && onValuesChange(values);
            } else {
              if(option) {
                setValue(option.value);
                onChange && onChange(option);
              }
            }
          }}
        />
        </>
      ))}
    </div>
  );
}
