import React, { useState, useEffect } from "react";
import { createCompetitor } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import CompetitorForm from "../../components/Forms/CompetitorForm";
import { getApiData } from "../../actions";
import Page from "../../components/Page";
import { useCookies } from "react-cookie";

const CompetitorNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);
  const [cookies] = useCookies(["token", "clubs"]);
  useEffect(() => {
    getApiData("availableClubs", "/api/all_clubs_available", dispatch);
  }, []);
  const onSubmit = async formProps => {
    if(!formProps.club) {
      return;
    }
    try {
      setSubmitting(true);
      await createCompetitor(
        formProps,
        dispatch,
        true,
        cookies.token === "1" ? "/competitors" : `/clubs/${cookies.clubs[0]}`
      );
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  };
  return (
    <Page title="Novi član">
      <CompetitorForm
        onSubmit={onSubmit}
        submitting={submitting}
        clubOptions={
          state.apiData["availableClubs"]
            ? state.apiData["availableClubs"].length
              ? state.apiData["availableClubs"]
              : [state.apiData["availableClubs"]]
            : []
        }
        initialFormProps={{
          dateOfBirth: new Date(1990, 25, 7),
          nationality: "HR",
          sex: "M"
        }}
      />
    </Page>
  );
};

export default CompetitorNew;
