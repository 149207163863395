import React, { useState } from 'react';
import PageHeader from '@atlaskit/page-header';
import Page from '@atlaskit/page';
import { createRank } from '../../actions';
import { useStateValue } from '../../contexts/AppContext';
import RankForm from '../../components/Forms/RankForm';

const RankNew = () => {
  const [state, dispatch] = useStateValue();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async formProps => {
    setSubmitting(true);
    await createRank(formProps, dispatch);
    setSubmitting(false);
  };
  return (
    <Page>
      <PageHeader>Novi rang utrke</PageHeader>
      <RankForm
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </Page>
  );
};

export default RankNew;
