import React, { useState, useEffect, useRef } from "react";
import { getData, getApiData } from "../../actions";
import { AddCompetitor } from "../../components/Modals";
import { addCompetitorToClub } from "../../actions";
import Page from "../../components/Page";
import createClubInfo from "./helpers/createClubInfo";
import Textfield from "@atlaskit/textfield";
import { useStateValue } from "../../contexts/AppContext";
import useSkipAdmin from "../../hooks/useSkipAdmin";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import filterData from "../../helpers/filterData";
import axios from "axios";
import ReactTable from "react-table";
import delayedCallback from "../../helpers/delayedCallback";
import { Link } from "react-router-dom";
import moment from "moment";
import { useCookies } from "react-cookie";
import LicenseStatus from "../../components/LicenseStatus";
import { Button } from "@atlaskit/button/components/Button";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import category from "../../helpers/category";
import { Checkbox } from "@atlaskit/checkbox";

const licenseHelper = (licenses, clubId) => {
  if (!licenses) return { value: 0, clubId };
  if (!Array.isArray(licenses)) {
    licenses = [licenses];
  }
  const currentYear = new Date().getFullYear();
  licenses = licenses.filter(
    (licence) => licence.createdat.substr(0, 4) == currentYear
  );
  if (licenses.length === 0) return { value: 0, clubId };
  if (licenses.filter((license) => license.approved === true).length > 0)
    return { value: 2, clubId };
  return { value: 1, clubId };
};

const helperFunctions = async (
  id,
  setClubData,
  setIsLoading,
  tableName,
  dispatch
) => {
  const [response1, response2] = await Promise.all([
    getData("/api/club", id),
    getApiData(tableName, `/api/competitor?clubId=${id}`, dispatch),
  ]);
  setClubData(response1.data);
  setIsLoading(false);
};

const Club = (props) => {
  const { id } = props.match.params;
  const tableName = `clubCompetitors-${id}`;
  const [isLoading, setIsLoading] = useState(true);
  const [clubData, setClubData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const competitorTable = useRef(null);
  const [state, dispatch] = useStateValue();
  const [isOpenLicensing, setIsOpenLicensing] = useState(false);
  const skipAdmin = useSkipAdmin(id);
  const [filter, setFilter] = useState(null);
  const [cookies] = useCookies(["token"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [licenseStatus, setLicenseStatus] = useState({});
  const [showUnlicensed, setShowUnlicensed] = useState(false);

  useEffect(() => {
    helperFunctions(id, setClubData, setIsLoading, tableName, dispatch);
  }, []);

  const actions = [
    {
      label: "Dodaj natjecatelja",
      onClick: () => setIsOpen(true),
      admin: true,
      skipAdmin,
    },
    {
      label: "Prijava na utrke",
      href: "/races",
      admin: true,
      skipAdmin,
    },
  ];

  const licensingActions = [
    {
      text: "Licenciraj sve natjecatelje",
      onClick: async () => {
        await Promise.all(
          state.apiData[tableName].map((competitor) => {
            try {
              axios.put("/api/license", {
                competitorId: competitor.id,
                clubId: id,
              });
            } catch (e) {}
          })
        );
        // window.location.reload();
      },
    },
    { text: "Close", onClick: () => setIsOpenLicensing(false) },
  ];

  const searchInputChange = (event) => {
    event.persist();
    if (event.target.value) {
      delayedCallback(event, setFilter);
    } else {
      setFilter(null);
    }
  };

  useEffect(() => {
    const newLiceseStatus = {};
    state.apiData[tableName] &&
      state.apiData[tableName].forEach(
        (competitor) =>
          (newLiceseStatus[competitor.id] = licenseHelper(
            competitor.Licenses,
            id
          ))
      );
    setLicenseStatus(newLiceseStatus);
  }, [state.apiData[tableName]]);

  const [categoryCount, setCategoryCount] = useState({});
  useEffect(() => {
    const count = {
      NELICENCIRANI: 0,
    };

    if (licenseStatus) {
      Object.entries(licenseStatus).forEach(([key, license]) => {
        if (license && license.value === 2) {
          const competitor = state.apiData[tableName].find(
            (competitor) => competitor.id == key
          );
          if (!competitor) {
            return;
          }
          if (!competitor.dateOfBirth) {
            return;
          }
          const years =
            new Date().getFullYear() -
            new Date(competitor.dateOfBirth).getFullYear();
          count[category(years)] = (count[category(years)] || 0) + 1;
        } else {
          count["NELICENCIRANI"] += 1;
        }
      });
      setCategoryCount(count);
    }
  }, [licenseStatus]);

  return (
    <Page
      title={clubData.name}
      toolBarActions={[
        "settings",
        { type: "data", url: `/api/natjecatelji.xls?clubId=${id}` },
      ]}
      toolBarSkipAdmin={skipAdmin}
      actions={actions}
      info={createClubInfo(clubData)}
    >
      <br />
      <Button
        style={{ width: "60px", justifyContent: "center", marginLeft: "850px" }}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <QuestionCircleIcon />
      </Button>
      {cookies.token === "1" &&
        Object.entries(categoryCount).map(([key, value]) => {
          return (
            <p>
              <span>{key}</span> -> <span>{value}</span>
            </p>
          );
        })}
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Textfield
          onChange={searchInputChange}
          placeholder="Filtriraj natjecatelje"
        />
        <Checkbox
          isChecked={showUnlicensed}
          label="Prikaži samo nelicencirane"
          onChange={(event) => {
            setShowUnlicensed(event.target.checked);
          }}
        />
      </div>
      {state.apiData[tableName] && (
        <ReactTable
          showPagination={state.apiData[tableName].length > 20}
          data={filterData(state.apiData[tableName], filter).filter((v) => {
            if (showUnlicensed) {
              return licenseHelper(v.Licenses, id).value === 0;
            }
            return true;
          })}
          columns={[
            {
              Header: "",
              accessor: "i",
              maxWidth: 50,
              Cell: (row) => (
                <span>{row.viewIndex + row.page * row.pageSize + 1}</span>
              ),
            },
            {
              Header: "Id",
              accessor: "id",
              maxWidth: 50,
            },
            {
              Header: "Ime",
              accessor: "name",
              maxWidth: 300,
              Cell: (row) => (
                <Link to={`/competitors/${row.original.id}`}>{row.value}</Link>
              ),
            },
            {
              Header: "Prezime",
              accessor: "surname",
              maxWidth: 300,
              Cell: (row) => (
                <Link to={`/competitors/${row.original.id}`}>{row.value}</Link>
              ),
            },
            {
              Header: "Datum rođenja",
              accessor: "dateOfBirth",
              maxWidth: 150,
              Cell: (row) => (
                <span>
                  {row.value ? moment(row.value).format("DD.MM.YYYY.") : "-"}
                </span>
              ),
            },
            {
              Header: "Spol",
              accessor: "sex",
              maxWidth: 50,
            },
            {
              Header: "Status licence",
              accessor: "Licenses",
              maxWidth: 150,
              Cell: (row) => (
                <LicenseStatus
                  status={licenseStatus[row.original.id]}
                  id={row.original.id}
                  token={cookies.token}
                  clubs={cookies.clubs}
                  clubId={id}
                  setLicense={(value) => {
                    licenseStatus[row.original.id].value = value;
                    setLicenseStatus({ ...licenseStatus });
                  }}
                />
              ),
            },
          ]}
          defaultSorted={[
            {
              id: "Licenses",
              desc: true,
            },
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
        />
      )}
      {isOpen && (
        <AddCompetitor
          setIsOpen={setIsOpen}
          onSubmit={async (formParams) => {
            setIsOpen(false);
            await addCompetitorToClub({ ...formParams, id });
            competitorTable.refresh();
          }}
        />
      )}
      {isOpenLicensing && (
        <ModalTransition>
          <Modal
            actions={licensingActions}
            onClose={() => setIsOpenLicensing(false)}
            heading="Licenciraj natjecatelje"
          >
            <p>Jeste li sigurni?</p>
          </Modal>
        </ModalTransition>
      )}
      {isModalOpen && (
        <Modal
          onClose={() => setIsModalOpen(false)}
          heading="Licenciranje natjecatelja"
        >
          <p>
            Nakon što odaberete natjecatelje koje želite licencirati.
            Administratori sustava će slijedeći <b>petak</b> poslati račun na
            vašu email adresu s uputama o plaćanju.
          </p>
          <span>
            Ukoliko smatrate da se radi o hitnom slučaju licenciranja. Javite se
            direktno na email HTS-a.
          </span>
        </Modal>
      )}
    </Page>
  );
};

export default Club;
