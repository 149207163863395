import React, { useEffect, useState } from "react";
import { getApiData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import moment from "moment";
import Page from "../../components/Page";
import Select from "@atlaskit/select";
import formatOptions from "../../helpers/formatOptions";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import LinkButton from "../../components/LinkButton";
import EditFilledIcon from "@atlaskit/icon/glyph/edit-filled";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import DocumentsIcon from "@atlaskit/icon/glyph/documents";
import { useCookies } from "react-cookie";
import { Checkbox } from "@atlaskit/checkbox";
import filterData from "../../helpers/filterData";
import Textfield from "@atlaskit/textfield";
import delayedCallback from "../../helpers/delayedCallback";

const MAX_ROWS_PER_PAGE = 50;

const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2018 },
  (_, index) => {
    return { value: 2019 + index, label: 2019 + index };
  }
);

const Races = (props) => {
  const { id } = props.match.params;
  const [state, dispatch] = useStateValue();
  const [cupId, setCupId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [raceTypeId, setRaceTypeId] = useState(null);
  const [filter, setFilter] = useState("");
  const [year, setYear] = useState(null);
  const [cookies] = useCookies(["token"]);
  const [onlyFutureRaces, setOnlyFutureRaces] = useState(false);
  const [groupedEvents, setGroupedEvents] = useState({});
  const [showEvents, setShowEvents] = useState([]);
  const [nextRace, setNextRace] = useState("");

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    getApiData("raceEvents", "/api/race", dispatch, {
      categoryId,
      raceTypeId,
      year,
      cupId,
      ...(onlyFutureRaces ? { first: 100 } : {}),
    });
  }, [categoryId, raceTypeId, year, cupId, onlyFutureRaces]);

  useEffect(() => {
    if (!state.apiData["raceEvents"]) {
      return;
    }
    const newGroupedEvents = {};
    let nextRaceKey = "";
    let nextRaceDate = null;
    state.apiData["raceEvents"].forEach((raceEvent) => {
      const raceKey =
        raceEvent.name + moment(raceEvent.date).format("DD.MM.YYYY");
      if (!newGroupedEvents[raceKey]) {
        newGroupedEvents[raceKey] = [];
        if (
          new Date(raceEvent.date).getTime() > new Date().getTime() &&
          ((nextRaceDate &&
            new Date(raceEvent.date).getTime() < nextRaceDate) ||
            !nextRaceDate)
        ) {
          nextRaceDate = new Date(raceEvent.date).getTime();
          nextRaceKey = raceKey;
        }
      }
      raceEvent.raceKey = raceKey;
      newGroupedEvents[raceKey].push(raceEvent);
    });
    Object.entries(newGroupedEvents).forEach(([key, value]) => {
      newGroupedEvents[key] = value.sort((a, b) => b.categoryId - a.categoryId);
    });
    if (nextRaceDate) {
      setNextRace(nextRaceKey);
    }
    setGroupedEvents(newGroupedEvents);
  }, [state.apiData["raceEvents"]]);

  const refresh = async () => {
    getApiData("categories", "/api/category", dispatch);
    getApiData("cups", "/api/cup", dispatch);
    getApiData("raceTypes", "/api/raceType", dispatch);
  };

  const searchInputChange = (event) => {
    event.persist();
    delayedCallback(event, setFilter);
  };

  const sortedEvents =
    state.apiData["raceEvents"] && groupedEvents
      ? Object.values(groupedEvents)
          .filter((data) => data.length && filterData(data, filter).length > 0)
          .sort(
            (a, b) =>
              new Date(b[0].date).getTime() - new Date(a[0].date).getTime()
          )
      : [];

  return (
    <Page
      title="Utrke"
      actions={[{ label: "Dodaj novu utrku", href: "/races/new", admin: true }]}
      search={
        id === undefined && (
          <>
            <Textfield
              onChange={searchInputChange}
              placeholder="Filtriraj utrke"
            />
            <br />
            <Select
              options={formatOptions(state.apiData["cups"])}
              onChange={(option) => {
                setCupId(option.value);
              }}
              placeholder="Odaberite kup"
            />
            <br />
            <Select
              options={yearOptions}
              onChange={(option) => {
                setYear(option.value);
              }}
              placeholder="Odaberite godinu"
            />
            <br />
            <Select
              options={formatOptions(state.apiData["categories"])}
              onChange={(option) => {
                setCategoryId(option.value);
              }}
              placeholder="Odaberite kategoriju"
            />
            <br />
            <Select
              options={formatOptions(state.apiData["raceTypes"])}
              onChange={(option) => {
                setRaceTypeId(option.value);
              }}
              placeholder="Odaberite tip utrke"
            />
            <br />
            <Checkbox
              isChecked={onlyFutureRaces}
              label="Prikaži samo buduće utrke"
              onChange={(event) => {
                setOnlyFutureRaces(event.target.checked);
              }}
            />
          </>
        )
      }
    >
      <br />
      {sortedEvents.map((events, index) => {
        if (id && !events.find((event) => event.id == id)) {
          return;
        }
        events = events.sort((a, b) => a.categoryId - b.categoryId);
        const date1 = new Date(events[0].date);
        const date2 = new Date();
        const diffTime = date2 - date1;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
        const showDiffDays = events[0].raceKey === nextRace; // diffDays <= 0 && diffDays >= -5;
        return (
          <div key={index}>
            {(index === 0 ||
              new Date(events[0].date).getFullYear() <
                new Date(sortedEvents[index - 1][0].date).getFullYear()) && (
              <h1>{new Date(events[0].date).getFullYear()}</h1>
            )}
            <div
              onClick={() => {
                dispatch({
                  type: "showRaceEvents",
                  payload: index,
                });
              }}
              className="ReactTable -striped -highlight"
            >
              <div
                className="rt-tr -odd race-events-container"
                role="row"
                style={{
                  background: showDiffDays
                    ? index % 2
                      ? "#9fcdef"
                      : "#7bb5e0"
                    : index % 2
                    ? "#FFF"
                    : "#F7F7F7",
                }}
              >
                {[
                  "date",
                  "name",
                  "raceTypeName",
                  "rankName",
                  "location",
                  "diffDays",
                ].map((key) => (
                  <div
                    className="rt-td"
                    role="gridcell"
                    style={{
                      flex: "100 0 auto",
                      width: 100,
                      maxWidth:
                        key === "name" ? "auto" : key === "date" ? 100 : 200,
                      color: showDiffDays ? "#333" : "#000",
                    }}
                  >
                    {key === "date"
                      ? moment(events[0][key]).format("DD.MM.YYYY")
                      : key === "diffDays"
                      ? showDiffDays
                        ? `Za ${Math.abs(diffDays)} dana`
                        : ""
                      : events[0][key]}
                  </div>
                ))}
              </div>
            </div>
            {(id !== undefined ||
              (state.showRaceEvents &&
                state.showRaceEvents.includes(index))) && (
              <div>
                {(() => {
                  const pdfUrl = events.reduce(
                    (acc, cur) => (acc ? acc : cur.pdfUrl),
                    null
                  );
                  if (!pdfUrl) {
                    return null;
                  }
                  return (
                    <div style={{ padding: 20 }}>
                      <a href={pdfUrl} target="_blank">
                        Raspis utrke
                      </a>
                    </div>
                  );
                })()}
                <div style={{ padding: 20 }}>
                  <a
                    href={`/api/PrijavljeniNatjecatelji.xls?raceId=${
                      events[0].id
                    }&raceEvent=true`}
                    target="_blank"
                  >
                    Startne liste
                  </a>
                </div>
                <ReactTable
                  showPagination={false}
                  data={events}
                  columns={[
                    {
                      Header: "Utrka",
                      accessor: "name",
                      maxWidth: 200,
                      Cell: (row) => {
                        return row.original.results &&
                          row.original.results.length ? (
                          <Link to={`/races/${row.original.id}`}>
                            {row.value}
                          </Link>
                        ) : (
                          <Link to={`/races/${row.original.id}/startlist`}>
                            {row.value}
                          </Link>
                        );
                      },
                    },
                    {
                      Header: "Natjecateljska kat.",
                      accessor: "categoryName",
                      minWidth: 150,
                      maxWidth: 250,
                      Cell: (row) => (
                        <span
                          onClick={() => setCategoryId(row.original.categoryId)}
                        >
                          {row.value}{" "}
                          {row.original.raceTypeId !== 14 && row.original.sex}
                        </span>
                      ),
                    },
                    {
                      Header: "Disciplina",
                      accessor: "raceTypeName",
                      maxWidth: 250,
                      Cell: (row) => (
                        <span
                          onClick={() => setRaceTypeId(row.original.raceTypeId)}
                        >
                          {row.value}
                        </span>
                      ),
                    },
                    {
                      Header: "Rang utrke",
                      accessor: "rankName",
                      maxWidth: 250,
                      Cell: (row) => (
                        <span
                          onClick={() => setRaceTypeId(row.original.rankTypeId)}
                        >
                          {row.value}
                        </span>
                      ),
                    },
                    {
                      Header: "Prijava",
                      accessor: "allowRegistration",
                      show: cookies.token === "2",
                      maxWidth: 50,
                      Cell: (row) => {
                        if (row && row.value) {
                          return (
                            <LinkButton
                              appearance={row.value ? "primary" : "danger"}
                              to={`/races/${row.original.id}/register`}
                            >
                              <EditFilledIcon />
                            </LinkButton>
                          );
                        }
                        return <span>-</span>;
                      },
                    },
                    {
                      Header: "Rezultati",
                      accessor: "id",
                      maxWidth: 50,
                      Cell: (row) => (
                        <LinkButton
                          appearance={
                            row.original.results && row.original.results.length
                              ? "primary"
                              : "danger"
                          }
                          to={
                            cookies.token === "1"
                              ? `/races/${row.original.id}/edit`
                              : `/races/${row.original.id}`
                          }
                        >
                          <DocumentsIcon />
                        </LinkButton>
                      ),
                    },
                    {
                      Header: "Postavke",
                      accessor: "id",
                      maxWidth: 50,
                      show: cookies.token === "1",
                      Cell: (row) => (
                        <LinkButton to={`/races/${row.original.id}/settings`}>
                          <SettingsIcon />
                        </LinkButton>
                      ),
                    },
                  ]}
                  defaultPageSize={MAX_ROWS_PER_PAGE}
                  pageSize={
                    events.length < MAX_ROWS_PER_PAGE
                      ? events.length
                      : MAX_ROWS_PER_PAGE
                  }
                  className="-striped -highlight"
                />
              </div>
            )}
          </div>
        );
      })}
    </Page>
  );
};

export default Races;
