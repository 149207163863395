import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getApiData, updateData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import { Button } from "@atlaskit/button/components/Button";
import { Link } from "react-router-dom";
import Spinner from "@atlaskit/spinner";
import ChipList from "../ChipList";
import axios from "axios";

const postData = async (oldData, newData, raceData, dispatch, refresh) => {
  let results = oldData.results;
  results = results.map((result) => {
    let newResult = newData.find(
      (newResultData) => newResultData.id === result.id
    );
    if (newResult) {
      newResult.intervals = raceData.disciplines.map(
        (discipline) => newResult[discipline]
      );
      return { ...result, ...newResult };
    } else {
      console.log("Error");
      return result;
    }
  });
  const response = await updateData(
    "/api/result",
    { results, raceId: raceData.id },
    dispatch,
    `/races/${raceData.id}/edit`
  );
  if (response.status === 200) {
    console.log("Status ok");
    refresh();
  } else {
    console.log("Error");
  }
};

const ResultTable = ({ tableName, apiRoute, raceData, editable }) => {
  const [state, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const [editDisabled, setEditDisabled] = useState(true);
  const [category, setCategory] = useState(null);

  const refresh = async () => {
    getApiData(tableName, apiRoute, dispatch);
  };
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (!raceData || !raceData.disciplines) return;
    if (state.apiData[tableName] && state.apiData[tableName].results) {
      const newData = state.apiData[tableName].results.map((result, index) => {
        return {
          ...{
            club: result.Club || {},
            id: result.id,
            position: result.status || index + 1,
            competitorId: result.Competitor.id,
            name: result.Competitor.name,
            surname: result.Competitor.surname,
            totalTime: result.totalTime,
            licenseId: result.licenseId,
            categories: result.Competitor.categories,
            error:
              result.licenseIdError ||
              result.totalTimeError ||
              result.multipleLicenseIdError ||
              "",
            diffTime: result.diffTime,
            diffPercentage: result.diffPercentage,
          },
          ...raceData.disciplines.reduce((obj, item, index) => {
            obj[item] = result.intervals[index];
            return obj;
          }, {}),
        };
      });
      setData(newData);
    }
  }, [state.apiData[tableName]]);

  const renderEditable = (cellInfo) => {
    return editable ? (
      <div
        style={{
          backgroundColor: "#fafafa",
        }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          setEditDisabled(false);
          const newData = [...data];
          newData[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          setData(newData);
        }}
        onFocus={() => {
          setEditDisabled(true);
        }}
        dangerouslySetInnerHTML={{
          __html: data[cellInfo.index][cellInfo.column.id],
        }}
      />
    ) : (
      data[cellInfo.index][cellInfo.column.id]
    );
  };

  const deleteResult = async (id) => {
    const response = await axios.delete(`${apiRoute}&id=${id}`);
    if (response.status === 200) {
      window.location.reload();
    } else {
      dispatch({ type: "error", payload: "Something went wrong" });
    }
  };

  const categories = data
    .reduce((acc, cur) => {
      const cats = cur.categories;
      const sex = cats[cats.length - 1];
      for (let i = 1; i < cats.length - 1; i++) {
        const newCat = sex + " " + cats[i];
        console.log(newCat);
        if (!acc.includes(newCat)) {
          acc.push(newCat);
        }
      }
      return acc;
    }, [])
    .sort();

  return data.length > 0 ? (
    <>
      <div style={{ paddingBottom: "40px", paddingTop: "40px" }}>
        <ChipList
          value={category}
          options={categories.map((c) => ({ label: c, value: c }))}
          onChange={(option) => {
            if (option) {
              if (category === option.value) {
                setCategory(null);
              } else {
                setCategory(option.value);
              }
            }
          }}
        />
      </div>
      {editable && (
        <Button
          isDisabled={editDisabled}
          appearance="primary"
          onClick={async () =>
            await postData(
              state.apiData[tableName],
              data,
              raceData,
              dispatch,
              refresh
            )
          }
        >
          Spremi promjene
        </Button>
      )}
      <ReactTable
        showPagination={false}
        data={data.filter((d) => {
          if (category) {
            const c = category.split(" ")[1];
            return d.categories.includes(c);
          }
          return true;
        })}
        columns={[
          {
            Header: "Pozicija",
            accessor: "position",
            maxWidth: 60,
            minWidth: 30,
          },
          {
            Header: "Ime",
            accessor: "name",
            Cell: (row) => (
              <Link to={`/competitors/${data[row.index].competitorId}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: "Prezime",
            accessor: "surname",
            Cell: (row) => (
              <Link to={`/competitors/${data[row.index].competitorId}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: "Kategorije",
            accessor: "categories",
            maxWidth: 350,
            Cell: (row) => <span>{row.value && row.value.join(" ")}</span>,
          },
          {
            Header: "Klub",
            accessor: "club",
            Cell: (row) => {
              return row.value && row.value.id ? (
                <Link to={`/clubs/${row.value.id}`}>
                  {row.value.name.replace("Triatlon klub ", "")}
                </Link>
              ) : (
                <span>-</span>
              );
            },
          },
          { Header: "Licenca", accessor: "licenseId", Cell: renderEditable },
          {
            Header: "Ukupno",
            accessor: "totalTime",
            Cell: renderEditable,
          },
          ...raceData.disciplines.map((discipline) => {
            return {
              Header: discipline,
              accessor: discipline,
              Cell: renderEditable,
            };
          }),
          {
            Header: "Vremenski zaostatak",
            accessor: "diffTime",
          },
          {
            Header: "Postotni zaostatak",
            accessor: "diffPercentage",
          },
          ...(editable
            ? [
                { Header: "Error", accessor: "error" },
                {
                  Header: "Akcije",
                  accessor: "id",
                  Cell: (cellInfo) => (
                    <div>
                      <Button
                        appearance="secondary"
                        onClick={() => {
                          deleteResult(cellInfo.original.id);
                        }}
                      >
                        Obriši
                      </Button>
                    </div>
                  ),
                },
              ]
            : []),
        ]}
        defaultPageSize={50}
        pageSize={data.length || 20}
        className="-striped -highlight"
      />
      <div>
        <h3>Tehnički delegat</h3>
        <p>{raceData.technicalDelegate}</p>
      </div>
      <div>
        <h3>Žalbeno vijeće</h3>
        <p>{raceData.competitionJury}</p>
      </div>
      <div>
        <h3>Komentar</h3>
        <p>{raceData.comment}</p>
      </div>
    </>
  ) : (
    <Spinner />
  );
};

export default ResultTable;
