import React, { useState } from "react";
import { Link } from "react-router-dom";
import IssueIcon from "@atlaskit/icon/glyph/issue";
import Axios from "axios";

const ClubToolButton = ({ type }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  if (type === "confirmation") {
    const paths = window.location.pathname.split("/");
    const id = paths.reduce((acc, cur) => {
      if (!isNaN(cur)) {
        return cur;
      }
      return acc;
    }, 0);
    const url = `/api/confirmation.docx?id=${id}`;
    return (
      <a
        title="Potvrda"
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          setIsDisabled(true);
          setTimeout(() => {
            setIsDisabled(false);
          }, 5000);
        }}
        href={url}
        download
      >
        <IssueIcon size="large" primaryColor="black" />
      </a>
    );
  }
  return null;
};

export default ClubToolButton;
