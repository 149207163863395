import React, { useState } from "react";
import ExcelSpreadsheet24Icon from "@atlaskit/icon-file-type/glyph/excel-spreadsheet/24";

const ToolButton = ({ type, url }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  if (type === "data") {
    return (
      <a onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 5000);
      }} href={url} download>
        <ExcelSpreadsheet24Icon size="large" />
      </a>
    );
  }
  return null;
};

export default ToolButton;
