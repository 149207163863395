import React, { useState, useEffect } from "react";
import PageHeader from "@atlaskit/page-header";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getApiData } from "../../actions";
import { useStateValue } from "../../contexts/AppContext";
import axios from "axios";
import formatOptions from "../../helpers/formatOptions";
import Page from "../../components/Page";
import ChipList from "../../components/ChipList";

const noData = { races: [], racePointsCompetitor: [] };

const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2018 },
  (_, index) => {
    return { value: 2019 + index, label: 2019 + index };
  }
);

const ageGroupDelta = (category) => {
  return category.name === "APSOLUTNO" ? 5 : 2;
};

const removeCups = (cups, selectedYear) => {
  if (selectedYear < 2020) {
    cups = cups.filter((cup) => cup.id < 4);
  } else if (selectedYear >= 2020) {
    cups = cups.filter((cup) => [4].includes(cup.id));
  }
  return cups;
};

const Cup = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [categoryId, setCategoryId] = useState(null);
  const [category, setCategory] = useState(null);
  const [cupId, setCupId] = useState(4);
  const [cupName, setCupName] = useState("");
  const [data, setData] = useState(noData);
  const [state, dispatch] = useStateValue();
  const [minAge, setMinAge] = useState(null);

  useEffect(() => {
    document.title = "Kup Hrvatske";
    getApiData("categories", "/api/category", dispatch);
    getApiData("cups", "/api/cup", dispatch);
  }, []);
  console.log({ minAge });
  const extraParams = minAge
    ? ageGroupDelta(category) === 2
      ? { minAge: minAge - 1, maxAge: minAge + ageGroupDelta(category) - 2 }
      : { minAge, maxAge: minAge + ageGroupDelta(category) - 1 }
    : {};

  const generateXlsUrl = () => {
    const params = {
      cupId,
      categoryId,
      year,
      ...extraParams,
    };
    return (
      "/api/kup.xls?" +
      Object.entries(params).reduce(
        (acc, cur, index) =>
          acc + (index > 0 ? "&" : "") + cur[0] + "=" + cur[1],
        ""
      )
    );
  };

  const getCup = async () => {
    if (cupId && categoryId && year) {
      try {
        const cup = await axios.get(`/api/cupOrder`, {
          params: {
            cupId,
            categoryId,
            year,
            ...extraParams,
          },
        });
        if (cup.status === 200) {
          //Sort race points
          if (cup.data.racePointsCompetitor) {
            cup.data.racePointsCompetitor.forEach((competitor, index) => {
              cup.data.racePointsCompetitor[
                index
              ].racePoints = cup.data.racePointsCompetitor[
                index
              ].racePoints.sort((a, b) => a.points < b.points);
            });
          }

          return setData(cup.data);
        } else {
          console.log("Error");
          return setData(noData);
        }
      } catch (e) {
        console.log(e);
      }
      return setData(noData);
    }
  };

  useEffect(() => {
    getCup();
  }, [year, categoryId, cupId, minAge]);

  useEffect(() => {
    if (state.apiData["cups"] && !state.apiData["cups"][3]) {
      return;
    }
    if (state.apiData["cups"] && state.apiData["cups"].length) {
      setCupId(state.apiData["cups"][3].id);
      setCupName(state.apiData["cups"][3].name);
    }
  }, [state.apiData["cups"]]);

  useEffect(() => {
    if (state.apiData["categories"] && state.apiData["categories"].length) {
      setCategoryId(
        (
          state.apiData["categories"].sort((a, b) => a.id > b.id)[8] ||
          state.apiData["categories"].sort((a, b) => a.id > b.id)[0]
        ).id
      );
      setCategory(
        state.apiData["categories"][8] || state.apiData["categories"][0]
      );
    }
  }, [state.apiData["categories"]]);

  return (
    <Page
      title="Kup Hrvatske"
      toolBarActions={["settings", { type: "data", url: generateXlsUrl() }]}
      search={
        <>
          {state.apiData["cups"] && (
            <ChipList
              defaultValue={
                (formatOptions(state.apiData["cups"])[3] &&
                  formatOptions(state.apiData["cups"])[3].value) ||
                formatOptions(state.apiData["cups"])[0].value
              }
              options={formatOptions(removeCups(state.apiData["cups"], year))}
              onChange={(option) => {
                if (option) {
                  setCupId(option.value);
                  setCupName(option.label);
                  setMinAge(null);
                }
              }}
            />
          )}
          <br />
          <ChipList
            defaultValue={new Date().getFullYear()}
            options={yearOptions}
            onChange={(option) => {
              if (option) {
                setYear(option.value);
                setMinAge(null);
              }
            }}
          />
          <br />
          {state.apiData["categories"] && (
            <ChipList
              defaultValue={
                (
                  formatOptions(state.apiData["categories"])[8] ||
                  formatOptions(state.apiData["categories"])[0]
                ).value
              }
              options={formatOptions(state.apiData["categories"])
                .filter((category) => {
                  if (year >= 2020) {
                    return true;
                  }
                  if (cupName.includes("Mini")) {
                    return category.label.toLowerCase().includes("etnici");
                  } else {
                    return !category.label.toLowerCase().includes("etnici");
                  }
                })
                .sort((a, b) =>
                  a.label.toLowerCase().includes("etnici") ? 1 : -1
                )
                .map((a) => {
                  if (a.label === "POČETNICI B M") {
                    a.breakLine = true;
                  }
                  return a;
                })}
              onChange={(option) => {
                if (option) {
                  setCategoryId(option.value);
                  setCategory(
                    state.apiData["categories"].find(
                      (cat) => cat.id === option.value
                    )
                  );
                  setMinAge(null);
                }
              }}
            />
          )}
          <br />
          {category && !cupName.includes("Mini") && (
            <ChipList
              value={minAge}
              options={Array.from(
                { length: ageGroupDelta(category) < 5 ? 2 : 15 },
                (value, index) => {
                  if (category.name.includes("APS")) {
                    const ageCount = 20 + index * ageGroupDelta(category);
                    return {
                      label: category.sex + " " + ageCount,
                      value: ageCount,
                    };
                  } else if (category.name.includes("JUNIORI")) {
                    const ageCount = 17 + index * 2;
                    return {
                      label: "U " + ageCount,
                      value: ageCount,
                    };
                  } else if (category.name.includes("KADETI")) {
                    const ageCount = 13 + index * 2;
                    return {
                      label: "U " + ageCount,
                      value: ageCount,
                    };
                  }
                }
              )}
              onChange={(option) => {
                if (option) {
                  setMinAge(option.value);
                }
              }}
            />
          )}
        </>
      }
    >
      <PageHeader>{cupName}</PageHeader>
      {cupId && categoryId && year && (
        <ReactTable
          showPagination={false}
          data={data.racePointsCompetitor}
          columns={[
            {
              Header: "",
              accessor: "position",
              maxWidth: 50,
              minWidth: 40,
            },
            {
              Header: "Ime i prezime",
              accessor: "name",
              minWidth: 200,
              maxWidth: 200,
              Cell: (row) => (
                <Link to={"/competitors/" + row.original.id}>
                  {row.original.name} {row.original.surname}
                </Link>
              ),
            },
            {
              Header: "Klub",
              accessor: "clubName",
              maxWidth: 100,
              Cell: (row) => (
                <Link to={"/clubs/" + row.original.clubId}>
                  {row.value.replace("Triatlon klub", "")}
                </Link>
              ),
            },
            {
              Header: "UKUPNO",
              accessor: "totalPoints",
              maxWidth: 80,
              minWidth: 80,
              getProps: () => {
                return {
                  style: {
                    background: "#3947A644",
                    color: "#454136",
                  },
                };
              },
              Cell: (row) => (
                <span>
                  <b>{row.value}</b>
                </span>
              ),
            },
            ...data.races.map((race) => {
              return {
                Header: <Link to={"/races/" + race.id}>{race.name}</Link>,
                minWidth: 50,
                maxWidth: 100,
                accessor: "racePoints",
                Cell: (row) => {
                  const targetRace = row.original.racePoints.find(
                    (r) => r.raceId === race.id
                  );
                  const points =
                    targetRace && targetRace.points ? targetRace.points : 0;
                  if (points > 0) {
                    if (targetRace.isCountingToFinal) {
                      return (
                        <span style={{ color: "blue" }}>
                          <b>{points}</b>
                        </span>
                      );
                    }
                    return <span>{points}</span>;
                  }
                  return <span>{points}</span>;
                },
              };
            }),
            ...(year > 2020 &&
            [1, 2].includes(categoryId) &&
            !data.races.find((race) => race.maxPoints === 300)
              ? [
                  {
                    Header: " ",
                    accessor: "qualifyPoints",
                    minWidth: 100,
                    Cell: (row) => {
                      return <span />;
                    },
                  },
                  {
                    Header: "Kvalifikacijski bodovi",
                    accessor: "qualifyPoints",
                    maxWidth: 200,
                    minWidth: 80,
                    Cell: (row) => {
                      if (
                        row.original.quilifyRank <=
                          (categoryId === 1 ? 60 : 60) &&
                        row.original.qualifyPoints >= 0
                      ) {
                        return (
                          <span style={{ color: "blue" }}>
                            <b>{row.value}</b>
                          </span>
                        );
                      } else {
                        return (
                          <span style={{ color: "red" }}>
                            <b>{row.value}</b>
                          </span>
                        );
                      }
                    },
                  },
                  {
                    Header: "Kvalifikacijski poredak",
                    accessor: "quilifyRank",
                    maxWidth: 200,
                    minWidth: 80,
                    Cell: (row) => {
                      if (
                        row.original.quilifyRank <=
                          (categoryId === 1 ? 60 : 60) &&
                        row.original.qualifyPoints >= 0
                      ) {
                        return (
                          <span style={{ color: "blue" }}>
                            <b>{row.value}</b>
                          </span>
                        );
                      } else {
                        return (
                          <span style={{ color: "red" }}>
                            <b>{row.value}</b>
                          </span>
                        );
                      }
                    },
                  },
                ]
              : []),
          ]}
          defaultPageSize={50}
          pageSize={data.racePointsCompetitor.length || 20}
          className="-striped -highlight"
        />
      )}
    </Page>
  );
};

export default Cup;
